.listings-page {
  .listings-table {
    .listings-table__caption {
      border: 1px solid #E6EAEE;
      border-bottom: 0;

      .listings-table__caption__section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: start;
        background-color: #fff;

        &.listings-table__caption--top {
          border-bottom: 1px solid #E6EAEE;
        }
      }

      .c-table__title {
        border: 0;
      }

      .listings-table__caption__title {
        margin: 0;
        border: 0;
      }

      .listings-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .listings-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .listings-table__header {
      top: 200px;
    }

    .table__cell__show-image {
      cursor: pointer;
    }
  }
}

.content-bootstrap-feel-listings {
  .dropdown__container {
    & > div {
      padding: 2px;
    }
  }
}
