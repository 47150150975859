.historical-reports-modal {
  background-color: #eff3f6;
  padding: 21px 50px 21px 50px;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #7f8fa4;
    max-height: 500px;
    overflow: auto;

    .historical-reports-table {
      border: 2px solid #E6EAEE;
      
      thead {
        color: #7f8fa4;
        
        td {
          padding: 10px 5px 10px 5px;
        }
      }

      tbody {
        background-color: #FFFFFF;

        .report__item {
          td {
            text-align: center;
            padding: 10px 5px 10px 5px;

            &:first-child {
              color: #000000;
            }

            i.fa-download {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 35px;

    .c-btn {
      width: 137px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
