.c-navbar {
  display: flex;
  justify-content: space-between;
  background-position: center;
  background-attachment: inherit;

  .c-navbar__title {
    text-transform: capitalize;
    font-weight: lighter;
  }

  .c-navbar__back-button {
    display: flex;
    align-items: center;
    font-weight: lighter;
    cursor: pointer;

    &::before {
      content: '< ';
      margin-right: 5px;
      margin-bottom: 1px;
    }
  }

  .c-navbar__secondary-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: lighter;
    font-size: 1.2rem;

    & > .secondary-title__image {
      height: 40px;
      width: auto;
      margin-right: 10px;
    }
  }

  .map-content-btn-bootstrap-feel {
    width: auto;
  }
}

@media (min-width: 992px) {
  .c-navbar {
    padding: 0 52px;
    height: 90px;

    .c-navbar__title {
      font-size: 1.4rem;
    }
  }
}
