.edit-user-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 40vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 15px;

    .user-detail {
      padding: 0;
      .row {
        margin: 0;
        .col {
          padding: 0;
        }

        .col-sm {
          padding: 0;
        }

        .col-sm:not(:last-child) {
          margin-right: 10px
        }
      }

      .user-detail__info-title {
        font-weight: 500;
        font-size: 0.7rem;
      }

      .user-detail__info-input {
        font-size: 0.7rem
      }
    }

    .modal__body__actions {
      width: 100%;

      .container {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
          justify-content: center;
          align-items: center;

          button {
            margin-top: 15px;
          }
        }

        .c-btn:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
