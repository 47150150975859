.manage-user-accounts-policy-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin: 0;
    }
  }

  .modal__body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;

    .searchBar {
      width: 100%;
      position: relative;
      margin-bottom: 10px;

      .c-field__icon {
        top: 5px !important;
      }
    }

    .collection {
      max-height: 50vh;
      position: relative;
      overflow-y: auto;

      tbody {
        .c-table__cell {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .c-table__cell--attachment {
          cursor: pointer;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }

        .c-table__row, .account-users, .user-form, .user-form__detail, .account__users-list { 
          flex-direction: column;          

          & > div {
            display: flex;
            justify-content: space-between;
            width: 100% !important;
          }
        }

        .account-users {
          align-items: center;

          .account__users-list {
            padding: 0 10px;

            .users-list__item {
              font-size: 0.875rem;
              font-weight: 500;
              border-top: 1px solid #E6EAEE;
              border-bottom: 1px solid #E6EAEE;
              padding: 10px;

              &.users-list__item--no-user {
                justify-content: center;
              }
            }

            &:not(:last-child) {
              margin-top: 5px;
            }
          }
        }

        .user-form {
          padding: 10px;
          font-size: .7rem;
          flex-direction: column;

          & > :not(:first-child)  {
            margin-top: 5px;
          }

          & > :last-child {
            margin-top: 10px;
          }

          .user-detail__info-title {
            font-weight: 500;
            font-size: 0.7rem;
            margin-bottom: 5px;
          }
        }
      }
    }

    .c-table {
      display: table;
      .c-table__cell--head {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      .c-table__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .c-table__cell--head,
      .c-table__cell {
        width: 30%;
        &:first-child {
          width: 10%;
          padding-left: 15px;
        }

        &:last-child {
          width: 50%;

          & > .c-btn {
            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }

      .c-table__cell--attachment {
        padding: 10px 0;
        width: 25%;
        word-break: break-all;
      }

      .c-table__cell--head {
        margin-right: 10px;
      }
    }
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    .c-btn {
      min-width: 140px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
