.file-input {
  display: flex;
  justify-content: center;
  width: 100%;

  input {
    width: 100%;
    margin: 0;
    border-right-color: gray;
  }

  span {
    color: $color-map-dark-blue-2;
    font-size: .6rem;
    padding: 5px;
    border: 2px solid $color-map-dark-blue-2;
    border-bottom-color: gray;
    border-right-color: gray;
    border-left: 0;
    border-radius: 0;
  }

  & > label {
    width: 100%;
  }
}
