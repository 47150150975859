.seller-detail-modal {
  font-family: "acumin-pro";
  padding: 0 16px;
  width: 300px;

  .modal__header {
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 10px;

    .seller-detail {
      .seller-detail__info {
        position: relative;
        .seller-detail__info-title {
          font-weight: 500;
          font-size: 0.7rem;
        }

        .seller-detail__info-input {
          font-size: 12px;
        }

        .seller-detail__info-image {
          position: absolute;
          max-width: auto;
          max-height: 20px;
          bottom: 7px;
          right: 5px;
        }
      }
    }

    .modal__body__actions {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      .c-btn {
        width: 137px;
      }
    }
  }
}
