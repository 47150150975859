.map-products-page {
  .map-products-page__main-section {
    padding: 0 26px;

    .map-products-table {
      border-radius: 6px;

      .map-products-table__caption {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border: 1px solid #E6EAEE;
        border-bottom: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .map-products-table__caption__title {
          margin: 0;
          border: 0;
          font-weight: lighter;
          text-transform: capitalize;
          font-size: 20px;
          color: #001D3D;
        }

        .map-products-table__filters {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;

          .map-products-table__filter {
            margin-right: 8px;
            text-transform: capitalize;

            &:last-child {
              margin-right: 0;
            }

            .c-navbar__search {
              width: 180px!important;
            }
          }
        }
      }

      .map-products-table__header {
        height: 57px;
        background-color: #F2F5FA;

        & > tr {
          & > th {
            color: #001D3D;

            &:first-child {
              padding-left: 19px;
            }

            &:last-child {
              padding-right: 19px;
            }

            & > a {
              color: #001D3D;
              text-decoration: none;
              font-size: .875rem;
            }
          }
        }
      }

      .map-products-table__body {
        & > tr {
          & > td {
            color: #001D3D;

            &.c-table__cell--text-underline {
              text-decoration: underline;
            }

            &.c-table__cell--danger {
              color: $color-map-pink;
            }

            &.c-table__cell__title {
              max-width: 200px;
            }

            &:first-child {
              padding-left: 19px;
            }

            &:last-child {
              padding-right: 19px;
            }

            & > a {
              color: #001D3D;
            }
          }
        }
      }
    }
  }
}
