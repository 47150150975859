///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

//
// Set up global Dashboard's variables.
//
// Don't change it here, instead, copy settings from this file into your custom
// settings' file to override Dashboard's default settings.
//

//
// Table of Content:
//
// 1. Color System
// 2. Typography
// 3. Backgrounds
// 4. Borders
// 5. Icon Fonts
// 6. Z-index System
// 7. Spacing
// 8. Breakpints
//

//
// 1. Color System
// ========================================================================== */

$grey-100: #f5f8fa !default;
$grey-200: #f2f4f7 !default; // #F7F9FB
$grey-300: #e6eaee !default; // eaeef1
$grey-400: #dfe3e9 !default; // dfe1e5 E3E8EF
$grey-500: #bbc5d5 !default;
$grey-600: #b7c0cd !default; // c2cad4
$grey-700: #9fa9ba !default;
$grey-800: #7f8fa4 !default; // 7f8fa4

$gunmetal-100: #475364 !default;
$gunmetal-200: #354052 !default; // main
$gunmetal-300: #2d3848 !default;
$gunmetal-400: #273142 !default;
$gunmetal-500: #1d2531 !default;


$pink-100: #f446e6 !default;
$pink-200: #f12de1 !default; // main
$pink-300: #cb88c5 !default;
$pink-400: #c251b9 !default;
$pink-500: #3d0939 !default;

$white: #fff !default;
$black: #000 !default;

$blue-100: #86CAFF !default;
$blue-200: #53b2f9 !default;
$blue-300: #1090f1 !default; // main
$blue-400: #1990ea !default;
$blue-500: #1a91eb !default; // 1991eb

$green-100: #65dc52 !default;
$green-200: #1bb934 !default;
$green-300: #39b54a !default;
$green-400: #34aa44 !default; //36af47
$green-500: #249533 !default;

$purple-100: #9d8fe3 !default;
$purple-200: #a388e3 !default;
$purple-300: #886ce6 !default;
$purple-400: #8261e6 !default;
$purple-500: #7d5be2 !default;

$red-100: #f95359 !default;
$red-200: #ed1c24 !default;
$red-300: #d35847 !default;
$red-400: #3095f9 !default;
$red-500: #db161e !default;

$orange-100: #f8c95d !default;
$orange-200: #f7bf47 !default;
$orange-300: #fd9a18 !default; //F7981C
$orange-400: #ff7800 !default;
$orange-500: #f16911 !default;

// states
$color-mute:    $grey-800 !default;
$color-success: $green-200 !default;
$color-info:    $blue-300 !default;
$color-warning: $orange-300 !default;
$color-danger:  $red-400 !default;
$color-fancy:   $purple-400 !default;

// gradients
$gradient-blue:   $blue-300, $blue-400 !default;
$gradient-green:  $green-300, $green-400 !default;
$gradient-purple: $purple-300, $purple-500 !default;
$gradient-red:    $red-100, $red-300 !default;
$gradient-orange: $orange-300, $orange-500 !default;

// brands
$color-facebook:  #2492E4 !default;
$color-twitter:   #0AF !default;
$color-pinterest: #E30F27 !default;
$color-behance:   #187AC6 !default;
$color-dribbble:  #ea4c89 !default;
$color-linkedin:  #222 !default;

$yellow-100: #F5cc39 !default;
$yellow-300: #E1BA2D !default;
//
// 2. Typography
// ========================================================================== */

$base-font-family: "Source Sans Pro", sans-serif !default;
$base-font-size: 16px !default;
$base-line-height: 24px !default;

// Font Weights
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;

// Paragraphs
$text-tiny-font-size: rem(10px) !default;
$text-small-font-size: rem(12px) !default;
$text-font-size: rem(14px) !default;
$text-large-font-size: rem(16px) !default;

$text-color: $gunmetal-200;
$text-strong-color: $gunmetal-200;
$text-mark-color: $white;
$text-mark-background-color: #ff0;
$text-mute-color: $color-mute;

// Headings
$h1-font-size: rem(36px) !default;
$h2-font-size: rem(28px) !default;
$h3-font-size: rem(24px) !default;
$h4-font-size: rem(20px) !default;
$h5-font-size: rem(18px) !default;
$h6-font-size: rem(16px) !default;

$heading-margin-bottom: rem(8px) !default;
$heading-color: $gunmetal-200;
$heading-font-weight: $font-weight-semibold;

// Links
$link-color: $blue-300 !default;
$link-text-decoration: none !default;
$link-hover-color: $blue-300 !default;
$link-hover-text-decoration: underline !default;


//
// 3. Backgrounds
// ========================================================================== */
$body-background-color: #EFF3F6 !default;
$base-background-color: $white !default;


//
// 4. Borders
// ========================================================================== */
$base-border-radius: 4px !default;
$base-border-style:  solid !default;
$base-border-color:  $grey-300 !default;
$base-border-width:  1px !default;


//
// 5. Icon Fonts
// ========================================================================== */
$base-icon-font: "FontAwesome" !default;


//
// 6. Z-index System
// ========================================================================== */
$z-index-100: 100 !default; // over content
$z-index-200: 200 !default; // over control
$z-index-300: 300 !default; // over page
$z-index-400: 400 !default; // over screen
$z-index-500: 500 !default; // over everything


//
// 7. Spacing
// ========================================================================== */

$spacer-xsmall: rem(10px);
$spacer-small:  rem(15px);
$spacer:        rem(30px);
$spacer-large:  rem(50px);
$spacer-xlarge: rem(100px);


//
// 8. Breakpoints
// ========================================================================== */

// Use these breakpoints with media-queries to create responsive website, these
// numbers are based on Bootstrap's grid breakpoints

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$breakpoint-mobile:  576px;
$breakpoint-tablet:  768px;
$breakpoint-desktop: 992px;
$breakpoint-wide:    1200px;
