.map-listings-more-filter-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 50vw;
  min-height: 229px;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;

    .row {
      align-items: center;
      justify-content: center;

      & > div {
        min-width: 200px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .c-field {
      .c-field__label {
        color: #001D3D;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-btn {
        font-size: 0.7rem;
      }
    }

    .modal__body__actions {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 305px;
      margin-top: 25px;

      .c-btn {
        width: 137px;
      }
    }
  }
}

.map-content-bootstrap-feel-more-filter {
  width: 198px;
}

.more-filters-type-filter {
  width: 144px;
}
