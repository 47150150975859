.product-detail-modal {
  font-family: "acumin-pro";
  padding: 0 16px;
  width: 35vw;
  min-height: 229px;
  max-height: 90vh;
  overflow: auto;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }

    .modal-title__product-img {
      max-width: 210px;
      max-height: 110px
    }



    .modal__carousel {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .swiper-container {
        width: 100%;

        .carousel__slide {
          width: 100%;
        }
      }

      .swiper-button-prev {
        font-size: 1.5rem;
        font-weight: 500;
        background-image: none;

        &:before {
          content: "<";
        }
      }


      .swiper-button-next {
        font-size: 1.5rem;
        font-weight: 500;
        background-image: none;

        &:before {
          content: ">";
        }
      }
    }
  }

  .modal__body {
    margin-top: 10px;

    .product-detail {
      padding: 0;
      .row {
        margin: 0;
        .col {
          padding: 0;
        }

        .col-sm {
          padding: 0;
        }

        .col-sm:not(:last-child) {
          margin-right: 10px
        }
      }

      .product-detail__divider {
        margin-top: 16px;
      }

      .product-detail__info-title {
        font-weight: 500;
        font-size: 0.7rem;
      }

      .product-detail__info-input {
        font-size: 0.7rem
      }

      .product-detail__info-img {
        max-width: 180px;
        max-height: 18px;
        position: absolute;
        bottom: 8px;
        left: 10px;
      }
    }

    .modal__body__actions {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-top: 10px;

      .c-btn {
        width: 137px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
