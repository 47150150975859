@import "./modals/send-notice-modal";
@import "./modals/sent-notice-modal";
@import "./modals/add-new-product-modal";
@import "./modals/historical-violations-modal";
@import "./modals/product-listing-detail-modal.scss";
@import "./modals/product-detail-modal.scss";
@import "./modals/copy-audit-report-modal.scss";
@import "./modals/add-new-product-display-modal.scss";
@import "./modals/map-product-display-modal.scss";
@import "./modals/seller-detail-modal.scss";
@import "./modals/violation-detail-modal.scss";
@import "./modals/edit-users-modal.scss";
@import "./modals/add-new-user-modal.scss";
@import "./modals/edit-user-modal.scss";
@import "./modals/add-user-accounts-modal.scss";
@import "./modals/update-account-state-modal.scss";
@import "./modals/new-seller-details-modal.scss";
@import "./modals/add-seller-details-modal.scss";
@import "./modals/manage-accounts-modal.scss";
@import "./modals/map-listings-more-filters-modal.scss";
@import "./modals/show-filters-modal.scss";
@import "./modals/confirmation-modal.scss";
@import "./modals/email-details-modal.scss";
@import "./modals/turn-on-off-modal.scss";
@import "./modals/email-list-upload-modal.scss";
@import "./modals/email-list-display-modal.scss";
@import "./modals/manage-emails-modal.scss";
@import "./modals/edit-stage-modal.scss";
@import "./modals/more-filters-modal.scss";
@import "./modals/manage-account-override-modal.scss";
@import "./modals/show-image-modal.scss";
@import "./modals/leave-note-modal.scss";
@import "./modals/manage-user-accounts-policy-modal.scss";
@import "./modals/closed-date-range-modal.scss";
@import "./modals/custom-date-range-modal.scss";
@import "./modals/cross-check-sellers-modal.scss";
@import "./modals/historical-reports-modal.scss";
@import "./modals/view-seller-alias-modal.scss";
@import "./modals/seller-history-modal.scss";
@import "./modals/new-reporting-system-modal.scss";

@import "./modals/last-seen-date-range-modal.scss";
@import "./modals/map-pricing-history-modal.scss";

.ember-modal-overlay {
  z-index: 400;
}

.ember-modal-dialog {
  .modal {
    max-height: 90vh;
  }

  .modal--large {
    @media (min-width: 992px) {
      width: 670px;
    }
  }
}
