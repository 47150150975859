@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import "vendor/scss/main.scss";
@import "vendor/scss/front.scss";
@import "ember-basic-dropdown";
@import "utilities";
@import "colors";
@import "buttons";
@import "base";

// COMPONENTS
@import "components/modals";
@import "components/main-header";
@import "components/account-switcher";
@import "components/navbar-component";
@import "components/search-input";
@import "components/calendar";
@import "components/notification";
@import "components/file-input";
@import "components/sub-statuses-cell";
@import "components/sub-statuses-count";
@import "components/search-filter-input";
@import "components/dark-mode";

// PAGES
@import "pages/index";
@import "pages/not-found-page";
@import "pages/map-page";
@import "pages/map-overview-page";
@import "pages/map-accounts-page";
@import "pages/map-products-page";
@import "pages/map-listings-page";
@import "pages/map-reports-page";
@import "pages/map-inbox-page";
@import "pages/map-account-products-page";
@import "pages/map-product-active-violations-page";
@import "pages/seller-listings-page";
@import "pages/listings-page";
@import "pages/sellers-page";
@import "pages/seller-white-list-page";
@import "pages/maplistings-page";
@import "pages/mapview-page";
@import "pages/account-page";
@import "pages/assets-page";
@import "pages/leads-page";
@import "pages/numbers-page";
@import "pages/account-report";
@import "pages/account-report-bulk";
@import "pages/reports-page";
@import "pages/seller-search-page";
@import "pages/map-clickup-dashboard";
@import "pages/referral-page";

.sizeToFitImage {
  width: auto;
  max-height: 50px;
}

.trigger-bootstrap-feel {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  outline: none;
}

.trigger-bootstrap-feel:focus, .trigger-bootstrap-feel:hover {
  background-color: #d4d4d4;
  border-color: #8c8c8c
}

.content-bootstrap-feel {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  padding: 5px;
  margin: 2px 0 0;
}

.content-bootstrap-feel--overflow-y {
  max-height: 200px;
  overflow-y: scroll;
}

.underlineClass {
  text-decoration: underline;
}

.rightSide {
  padding-top: 10px;
}

table.filterTable {
  /* width: 100%; */ /* Optional */
  /* border-collapse: collapse; */
  border-spacing: 0;
  border: 2px solid black;
}

table.filterTableFancy {
  /* width: 100%; */ /* Optional */
  /* border-collapse: collapse; */
  border-spacing: 0;
}

.cellPickYearFilter{
  font-size: 14px;
  color: #0750a8;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
}

.cellLeadTitle{
  font-size: 14px;
  color: gray;
  padding-left: 20px;
  //padding-right: 50px;
  padding-top: 10px;
  //padding-bottom: 10px;
 // border-bottom: 1px solid lightgrey;
  text-align: left;
  background-color: #f1f3f5;
}

.cellSellerDetailsTitleTop{
  font-size: 14px;
  color: gray;
  padding-left: 20px;
  //padding-right: 50px;
  padding-top: 10px;
  //padding-bottom: 10px;
  // border-bottom: 1px solid lightgrey;
  text-align: left;
  background-color: #f1f3f5;
}

.cellSellerDetailsTitle{
  text-align: left;
  font: normal normal medium 10px/12px Helvetica Neue;
  letter-spacing: 0px;
  color: #001D3D;
  opacity: 1;
  padding-left: 25px;
}

.cellSellerDetailsHeaderTitle{
  text-align: center;
  background: #061D3B 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal medium 9px/11px Helvetica Neue;
  letter-spacing: 0px;
  color: #FFFFFF;
  height: 29px;
}

.cellSellerDetailsHeaderBody{
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal medium 9px/11px Helvetica Neue;
  letter-spacing: 0px;
  color: #FFFFFF;
  height: 29px;
}

.sellerDetailsNumberStyle{
  text-align: center;
  font: normal normal medium 9px/11px Helvetica Neue;
  letter-spacing: 0px;
  color: #061D3B;
  opacity: 1;
}

.sellerDetailButtonText{
  text-align: center;
  font: normal normal medium 9px/11px Helvetica Neue;
  letter-spacing: 0px;
  color: #061D3B;
  opacity: 1;
}

.cellPickInputLead{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  //border-bottom: 1px solid lightgrey;
  text-align: center;
  background-color: #f1f3f5;
}

.cellPickInputSellerDetails{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  //border-bottom: 1px solid lightgrey;
  text-align: center;
  background-color: #FFFFFF;
}

.cellPickInputSellerDetailsAdd{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  //border-bottom: 1px solid lightgrey;
  text-align: center;
  background-color: #FFFFFF;
  float: left;
}

.cellPickInputLeadBorderBottom{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  background-color: #f1f3f5;
}

.cellPickInputSellerDetailsBorderBottom{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #FFFFFF;
}

.cellPickInputLeadBorderBottomButton{
  font-size: 14px;
  color: #0750a8;
  //padding-left: 10px;
  //padding-right: 30px;
  //padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  background-color: #f1f3f5;

  padding-left: 5px;
  padding-right: 5px;
}

.leadFieldClassNew{
  width: 190px;
  border: 1px solid #ccc;
  height: 40px;
  padding: 12px 10px;
  margin: 8px 0;
}

.cellLeadButton{
  font-size: 14px;
  color: #0750a8;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
}

.cellLSellerDetailsButtonTopThing{
  font-size: 14px;
  color: #0750a8;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.cellPickInput{
  font-size: 14px;
  color: #0750a8;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
}

.cellPickYearImageFilter{
  font-size: 14px;
  color: #0750a8;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.cellPickYearFilter  a:link {
  color: #0750a8;
  text-align: center;
}

.cellPickYearFilter a.active  {
  color: #0750a8;
  text-align: center;
}

.cellPickYearFilter a  {
  color: #0750a8;
  text-align: center;
}

.cellPickYearFilter a:hover {
  color: #0750a8;
  text-align: center;
}

.cellLeadButton a:link {
  text-align: center;
}

.cellLeadButton a.active  {
  text-align: center;
}

.cellLeadButton a {
  text-align: center;
}

.cellLeadButton a:hover {
  text-align: center;
}

.cellPickYearFilterEmpty{
  font-size: 14px;
  color: #0750a8;
  text-align: center;
  //padding-left: 50px;
  //padding-right: 50px;
  //padding-top: 10px;
  //padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}

.cellPickYearFilterImage{
  font-size: 14px;
  color: #0750a8;
  text-align: center;
}

.redColorText{
  color: red;
}

.redColorTextBold{
  color: red;
  font-weight: bold;
}

.greenColorText{
   color: green;
 }

.greenColorTextBold{
  color: green;
  font-weight: bold;
}

.greenColorTextLarge{
  color: green;
  font-weight: bold;
  font-size: xx-large;
}

.greyColorTextLarge{
  color: lightgray;
  font-weight: bold;
  font-size: xx-large;
}

.yellowColorText{
  color: yellow;
}

.goldColorText{
  color: darkgoldenrod;
}

.cancelText{
  color: red;
}

.smallText{
  color: black;
  font-size: 10px;
}

.blackColorText{
  color: black;
}

.blackColorTextBold{
  color: black;
  font-weight: bold;
}

.blackColorTextBoldFancy{
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 20px;
}

.blueText{
  color: deepskyblue;
}

.blueTextBold{
  color: dodgerblue;
  font-weight: bold;
}

.checkForm{
  width: 280px;
  height: 36px;
  font-size: 14px;
  font-weight: bold;
}

.sellerURLSectionTextArea{
  margin-left: 15px;
  margin-top: 10px;
}

.u-ml-medium_x{
  margin-left:0.775rem !important
}

.floatLeftFix{
  float: right;
}

.c-table__title {
  color: #001D3D;
}

.c-table__title-action_fixed{
  margin-right: 40px;
  position: absolute;
  top: 30px;
  right: 30px;
  color: #AFB4BB;
  font-size: 22px;
}

.topIntroWidth{
  width: auto;
}

table.filterImageTable {
  /* width: 100%; */ /* Optional */
  /* border-collapse: collapse; */
  border-spacing: 0;
}

table.filterTable tbody,
table.filterTable thead,
table.filterImageTable thead
{
  //display: block;
  text-align: center;
}

.condensedTable{
  max-height: 600px;
  overflow-y: auto;
}

.condensedTableMaxWidth{
  max-height: 600px;
  max-width: 1200px;
  overflow-y: auto;
}

.condensedTableMapViolations{
  max-height: 600px;
  overflow-y: auto;
  min-width: 350px;
  max-width: 6500px;
}

.condensedTableWide{
  max-height: 600px;
  overflow-y: auto;
  min-width: 600px;
  max-width: 900px;
}

.condensedTableExtraWide{
  max-height: 600px;
  overflow-y: auto;
  overflow-x: auto;
  width: 1600px;
}

.condensedTableMedium{
  max-height: 600px;
  overflow-y: auto;
  min-width: 350px;
  max-width: 1200px;
}

.rowTitle{
  text-align: center;
  background-color:  #d3d3d38c;
  margin-left: 2px;
  margin-right: 2px;
  height: 1px;
  margin-bottom: 20px;
}

.rowTitleText{
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 10px;
}

.indexTitles{
  font-size: 1.25rem;
}

.warningTitles{
  font-size: 1.50rem;
  color: red;
  font-weight: bold;
}

.listingDetailPopUp{
  width: 300px;
  height: 650px;
  background-color:#eff3f6 ;
}
.hardLeft {
  position:absolute; right:50px;
}
.title_for_task {
  font-size: 1.00rem;
  color: black;
  font-weight: bold;
  a:link {
    color: black;
  }

  /* visited link */
  a:visited {
    color: black;
  }

  /* mouse over link */
  a:hover {
    color: black;
  }

  /* selected link */
  a:active {
    color: black;
  }
}
@media print {
  #hubspot-messages-iframe-container {
    visibility: hidden;
  }
}
.listingDetailPopUpNoHeight{
  width: 310px;
  max-height: 90vh;
  overflow-y: auto;
  background-color:#eff3f6 ;
}


.listingDetailPopUpNoHeightWide{
  width: 600px;
  background-color:#eff3f6 ;
}

.leadDetailPopUp{
  width: 300px;
  height: 300px;
  background-color:#eff3f6 ;
}

.listingDetailPopUpNewListing{
  width: 300px;
  height: 90vh;
  overflow-y: auto;
  background-color:#eff3f6 ;
}

.dropdownPartner {
  margin-left: 12px;
  margin-top: 5px;
}

.dropdownPartnerLead {
  margin-left: 45px;
  margin-top: 5px;
}

.sellerDetailPopUp{
  width: 330px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color:#eff3f6 ;
}

.sellerDetailPopUpL{
  width: 330px;
  max-height: 90vh;
  background-color:#eff3f6 ;
  float: left;
}

.sellerDetailPopUpR{
  width: 320px;
  max-height: 90vh;
  background-color:#eff3f6 ;
  float: right;
}

.sellerDetailPopUpMapSeller{
  width: 300px;
  height: 250px;
  background-color:#eff3f6 ;
  float: right;
}

.sellerDetailPopUpFrame{
  width: 680px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color:#eff3f6 ;
}

.sellerDetailPopUpFrameMapSeller{
  width: 300px;
  height: 250px;
  background-color:#eff3f6 ;
}

.sellerNameTitle {
  color: #7f8fa4;
  font-size: 11px;
  height: 30px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.accountNameTitle {
  color: #7f8fa4;
  font-size: 15px;
  height: 30px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.leadNameTitle {
  color: #7f8fa4;
  font-size: 15px;
  height: 30px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.leadNameBody {
  color: #7f8fa4;
  font-size: 18px;

  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}


.accountNameTitleNewListing {
  color: #7f8fa4;
  font-size: 15px;
  height: 20px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.sellerNameTitleCenter {
  color: #7f8fa4;
  font-size: 11px;
  height: 30px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.sellerName {
  background-color: #FFFFFF;
  height: 30px;
  padding-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  padding-right: 10px;
  padding-left: 10px;
  color: #1090f1;
  font-size: 12px;
  //padding-bottom: 10px;
}

.listingDetailFormImage {
  width: auto;
  max-height: 20px;
  float: right;
}

.sellerUrlFieldClass {
  background-color: #FFFFFF;
  height: 30px;
  padding-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000000;
  font-size: 12px;
  width: 274px;
  padding-bottom: 10px;
}

.leadFieldClass {
  background-color: #FFFFFF;
  height: 30px;
  padding-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  padding-right: 5px;
  padding-left: 5px;
  color: #000000;
  font-size: 12px;
  width: 150px;
  padding-bottom: 10px;
}

.newListingFieldClass {
  background-color: #FFFFFF;
  height: 20px;
  padding-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000000;
  font-size: 12px;
  width: 274px;
  padding-bottom: 10px;
}

.newLeadFieldClass {
  background-color: #FFFFFF;
  height: 25px;
  padding-top: 5px;
  margin-left: 13px;
  margin-right: 13px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000000;
  font-size: 14px;
  width: 274px;
  padding-bottom: 10px;
}

.sellerFlagStatusSection {
  width: 300px;
  overflow:hidden;
}

.sellerFlag {
  width: 150px;
  float: left;
  color: #7f8fa4;
  font-size: 11px;
  height: 30px;
  padding-top: 5px;
  padding-left: 15px;
}
.sellerStatus {
  width: 150px;
  float: left;
  color: #7f8fa4;
  font-size: 11px;
  height: 30px;
  padding-top: 5px;
  padding-left: 5px;
}

.listingSubStatusFieldClass {
  background-color: #FFFFFF;
  height: 40px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000000;
  font-size: 12px;
  width: 137px;
  padding-bottom: 10px;

}

.sellerStatusButton {
  width: 150px;
  float: left;
  color: #7f8fa4;
  font-size: 11px;
  height: 40px;
  padding-top: 0px;
  padding-left: 5px;
}

.sellerFlagButton {
  width: 150px;
  float: left;
  color: #7f8fa4;
  font-size: 11px;
  height: 40px;
  padding-top: 0px;
  padding-left: 25px;
}

.sellerFlagContent {
  width: 150px;
  float: left;
  color: #7f8fa4;
  font-size: 11px;
  height: 30px;
  padding-top: 0px;
  padding-left: 15px;
}

.dividerLine {
  background-color: #e0e3e6;
  height: 1px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  clear: both;
}

.notesTextField {
  width: 100%;
  color: #000000;
  font-size: 12px;
  height: 60px;
}

.notesTextFieldContainer {
  padding-left: 15px;
  padding-right: 15px;
  width: 300px;
}

.vendorButtonInput {
  margin-top: 5px;
  text-align: center;
}

.sellerNameSectionCenter {
  text-align: center;
}

.sideBarLogoImage {
  width: 110px;
  margin-left: 24px;
  margin-top: 260px;
}

.sideBarLogoImage2 {
  width: 140px;
  margin-left: 6px;
  margin-top: 260px;
}

.countdownLine {
  text-align: center;
  color: #f60522;
  font-size: 48px;
  font-weight: bold;
}

/*the following html and body rule sets are required only if using a % width or height*/
/*html {
width: 100%;
height: 100%;
}*/
//body {
//  box-sizing: border-box;
//  width: 100%;
//  height: 100%;
//  margin: 0;
//  padding: 0 20px 0 20px;
//  text-align: center;
//  background: white;
//}
.scrollingtable {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  width: auto; /*if you want a fixed width, set it here, else set to auto*/
  min-width: 0/*100%*/; /*if you want a % width, set it here, else set to 0*/
  height: 100%/*100%*/; /*set table height here; can be fixed value or %*/
  min-height: 0/*104px*/; /*if using % height, make this large enough to fit scrollbar arrows + caption + thead*/
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 0 20px 0; /*need enough padding to make room for caption*/
  text-align: left;
}
.scrollingtable * {box-sizing: border-box;}
.scrollingtable > div {
  position: relative;
  border-top: 1px solid black;
  height: 100%;
  padding-top: 20px; /*this determines column header height*/
}
.scrollingtable > div:before {
  top: 0;
  background: cornflowerblue; /*header row background color*/
}
.scrollingtable > div:before,
.scrollingtable > div > div:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
}
.scrollingtable > div > div {
  min-height: 0/*43px*/; /*if using % height, make this large enough to fit scrollbar arrows*/
  max-height: 100%;
  overflow: scroll/*auto*/; /*set to auto if using fixed or % width; else scroll*/
  overflow-x: hidden;
  border: 1px solid black; /*border around table body*/
}
.scrollingtable > div > div:after {background: white;} /*match page background color*/
.scrollingtable > div > div > table {
  width: 100%;
  border-spacing: 0;
  margin-top: -20px; /*inverse of column header height*/
  /*margin-right: 17px;*/ /*uncomment if using % width*/
}
.scrollingtable > div > div > table > caption {
  position: absolute;
  top: -20px; /*inverse of caption height*/
  margin-top: -1px; /*inverse of border-width*/
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.scrollingtable > div > div > table > * > tr > * {padding: 0;}
.scrollingtable > div > div > table > thead {
  vertical-align: bottom;
  white-space: nowrap;
  text-align: center;
}
.scrollingtable > div > div > table > thead > tr > * > div {
  display: inline-block;
  padding: 0 6px 0 6px; /*header cell padding*/
}
.scrollingtable > div > div > table > thead > tr > :first-child:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px; /*match column header height*/
  border-left: 1px solid black; /*leftmost header border*/
}
.scrollingtable > div > div > table > thead > tr > * > div[label]:before,
.scrollingtable > div > div > table > thead > tr > * > div > div:first-child,
.scrollingtable > div > div > table > thead > tr > * + :before {
  position: absolute;
  top: 0;
  white-space: pre-wrap;
  color: white; /*header row font color*/
}
.scrollingtable > div > div > table > thead > tr > * > div[label]:before,
.scrollingtable > div > div > table > thead > tr > * > div[label]:after {content: attr(label);}
.scrollingtable > div > div > table > thead > tr > * + :before {
  content: "";
  display: block;
  min-height: 20px; /*match column header height*/
  padding-top: 1px;
  border-left: 1px solid black; /*borders between header cells*/
}
.scrollingtable .scrollbarhead {float: right;}
.scrollingtable .scrollbarhead:before {
  position: absolute;
  width: 100px;
  top: -1px; /*inverse border-width*/
  background: white; /*match page background color*/
}
.scrollingtable > div > div > table > tbody > tr:after {
  content: "";
  display: table-cell;
  position: relative;
  padding: 0;
  border-top: 1px solid black;
  top: -1px; /*inverse of border width*/
}
.scrollingtable > div > div > table > tbody {vertical-align: top;}
.scrollingtable > div > div > table > tbody > tr {background: white;}
.scrollingtable > div > div > table > tbody > tr > * {
  border-bottom: 1px solid black;
  padding: 0 6px 0 6px;
  height: 20px; /*match column header height*/
}
.scrollingtable > div > div > table > tbody:last-of-type > tr:last-child > * {border-bottom: none;}
.scrollingtable > div > div > table > tbody > tr:nth-child(even) {background: gainsboro;} /*alternate row color*/
.scrollingtable > div > div > table > tbody > tr > * + * {border-left: 1px solid black;} /*borders between body cells*/

//scrollingtablefixed
.scrollingtableFixed {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  width: auto; /*if you want a fixed width, set it here, else set to auto*/
  min-width: 0/*100%*/; /*if you want a % width, set it here, else set to 0*/
  height: 500px/*100%*/; /*set table height here; can be fixed value or %*/
  min-height: 0/*104px*/; /*if using % height, make this large enough to fit scrollbar arrows + caption + thead*/
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding: 0px 0 0px 0; /*need enough padding to make room for caption*/
  text-align: left;
}
.scrollingtableFixed * {box-sizing: border-box;}
.scrollingtableFixed > div {
  position: relative;
  border-top: 1px solid black;
  height: 100%;
  padding-top: 20px; /*this determines column header height*/
}
.scrollingtableFixed > div:before {
  top: 0;
  //background: cornflowerblue; /*header row background color*/
}
.scrollingtableFixed > div:before,
.scrollingtableFixed > div > div:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
}
.scrollingtableFixed > div > div {
  min-height: 0/*43px*/; /*if using % height, make this large enough to fit scrollbar arrows*/
  max-height: 100%;
  overflow: scroll/*auto*/; /*set to auto if using fixed or % width; else scroll*/
  overflow-x: hidden;
  border: 1px solid black; /*border around table body*/
}
.scrollingtableFixed > div > div:after {background: white;} /*match page background color*/
.scrollingtableFixed > div > div > table {
  width: 100%;
  border-spacing: 0;
  margin-top: -20px; /*inverse of column header height*/
  /*margin-right: 17px;*/ /*uncomment if using % width*/
}
.scrollingtableFixed > div > div > table > caption {
  position: absolute;
  top: -20px; /*inverse of caption height*/
  margin-top: -1px; /*inverse of border-width*/
  width: 100%;
  font-weight: bold;
  text-align: center;
}
.scrollingtableFixed > div > div > table > * > tr > * {padding: 0;}
.scrollingtableFixed > div > div > table > thead {
  vertical-align: bottom;
  white-space: nowrap;
  text-align: center;
}
.scrollingtableFixed > div > div > table > thead > tr > * > div {
  display: inline-block;
  padding: 0 6px 0 6px; /*header cell padding*/
}
.scrollingtableFixed > div > div > table > thead > tr > :first-child:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px; /*match column header height*/
  border-left: 1px solid black; /*leftmost header border*/
}
.scrollingtableFixed > div > div > table > thead > tr > * > div[label]:before,
.scrollingtableFixed > div > div > table > thead > tr > * > div > div:first-child,
.scrollingtableFixed > div > div > table > thead > tr > * + :before {
  position: absolute;
  top: 0;
  white-space: pre-wrap;
  color: white; /*header row font color*/
}
.scrollingtableFixed > div > div > table > thead > tr > * > div[label]:before,
.scrollingtableFixed > div > div > table > thead > tr > * > div[label]:after {content: attr(label);}
.scrollingtableFixed > div > div > table > thead > tr > * + :before {
  content: "";
  display: block;
  min-height: 20px; /*match column header height*/
  padding-top: 1px;
  border-left: 1px solid black; /*borders between header cells*/
}
.scrollingtableFixed .scrollbarhead {float: right;}
.scrollingtableFixed .scrollbarhead:before {
  position: absolute;
  width: 100px;
  top: -1px; /*inverse border-width*/
  background: white; /*match page background color*/
}
.scrollingtableFixed > div > div > table > tbody > tr:after {
  content: "";
  display: table-cell;
  position: relative;
  padding: 0;
  border-top: 1px solid black;
  top: -1px; /*inverse of border width*/
}
.scrollingtableFixed > div > div > table > tbody {vertical-align: top;}
.scrollingtableFixed > div > div > table > tbody > tr {background: white;}
.scrollingtableFixed > div > div > table > tbody > tr > * {
  border-bottom: 1px solid black;
  padding: 0 6px 0 6px;
  height: 20px; /*match column header height*/
}
.scrollingtableFixed > div > div > table > tbody:last-of-type > tr:last-child > * {border-bottom: none;}
.scrollingtableFixed > div > div > table > tbody > tr:nth-child(even) {background: gainsboro;} /*alternate row color*/
.scrollingtableFixed > div > div > table > tbody > tr > * + * {border-left: 1px solid black;} /*borders between body cells*/

.centerText{
  text-align: center;
}

.sellerHeaderTitle {
  text-align: center;
  font: normal normal 300 22px/27px Helvetica Neue;
  letter-spacing: 0px;
  color: #001D3D;
  opacity: 1;
}

.sellerTitleField{
  text-align: left;
  font: normal normal medium 9px/11px Helvetica Neue;
  letter-spacing: 0px;
  color: #001D3D;
  opacity: 1;
}

.sellerHeaderXButton{
  text-align: right;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #001D3D;
  opacity: 1;
}

.parent-company-dropdown {
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 15px;
  line-height: 20px
}

.c-custom-file {
  padding-left: 15px;
  padding-right: 13px;
  width: 300px;
}

.reasonSection {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .reasons__list {
    width: 270px;
    max-height: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 15px;
    border: 1px solid #000000;

    .reasons__list__item {
      background-color: #FFFFFF;
      font-size: .8rem;
      padding: 5px;

      &:not(:last-child) {
        border-bottom: 1px solid #000000;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.map-content-bootstrap-feel {
  max-height: 200px;
  overflow-y: auto;

  .dropdown-btn-width {
    max-width: 6rem;
  }

  .dropdown__item {
    padding: 5px 15px 5px 15px;
  }

  .dropdown__item__select {
    padding: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #16181b;
      background-color: #e9ecef;
    }
  }

  .c-btn--danger {
    background-color: $color-map-pink;
    border-color: $color-map-pink;
    background: -webkit-gradient(linear,left top,left bottom,from($color-map-pink),to($color-map-pink));
    background-color: linear-gradient(to bottom, $color-map-pink, $color-map-pink);
  }

  .c-btn--danger-gradient {
    &:hover {
      background: -webkit-gradient(linear,left top,left bottom,from($color-map-pink),to($color-map-dark-red)) ;
      background-color: linear-gradient(to bottom, $color-map-pink, $color-map-dark-red) ;
    }
  }

	.c-btn--danger-2 {
		background-color: $color-map-hot-magenta;
		border-color: $color-map-hot-magenta;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-hot-magenta),to($color-map-hot-magenta));
		background-color: linear-gradient(to bottom, $color-map-hot-magenta, $color-map-hot-magenta);
	}

	.c-btn--danger-2-gradient {
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-hot-magenta),to($color-map-pink));
			background-color: linear-gradient(to bottom, $color-map-hot-magenta, $color-map-pink);
		}
	}

	.c-btn--danger-3 {
		background-color: $color-map-eletric-purple;
		border-color: $color-map-eletric-purple;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-eletric-purple),to($color-map-eletric-purple));
		background-color: linear-gradient(to bottom, $color-map-eletric-purple, $color-map-eletric-purple);
	}

	.c-btn--danger-3-gradient {
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-eletric-purple),to($color-map-hot-magenta));
			background-color: linear-gradient(to bottom, $color-map-eletric-purple, $color-map-hot-magenta);
		}
  }

  .c-btn--info {
		background-color: $color-map-light-blue-1;
		border-color: $color-map-light-blue-1;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-light-blue-1),to($color-map-light-blue-1));
		background-color: linear-gradient(to bottom, $color-map-light-blue-1, $color-map-light-blue-1);
	}

  .c-btn--info-gradient{
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-light-blue-1),to($color-map-blue));
			background-color: linear-gradient(to bottom, $color-map-light-blue-1, $color-map-blue);
		}
	}

  .c-btn--info-solid{
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-light-blue-2),to($color-map-light-blue-2));
			background-color: linear-gradient(to bottom, $color-map-light-blue-2, $color-map-light-blue-2);
		}
	}

  .c-btn--primary {
    background-color: $color-map-dark-blue-2;
    border-color: $color-map-dark-blue-2;
    background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-blue-2),to($color-map-dark-blue-2));
    background-color: linear-gradient(to bottom, $color-map-dark-blue-2, $color-map-dark-blue-2);
  }

  .c-btn--primary-gradient {
    &:hover {
      background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-blue-1),to($color-map-black));
      background-color: linear-gradient(to bottom, $color-map-dark-blue-1, $color-map-black);
    }
  }

	.c-btn--secondary {
		background-color: $color-map-grey;
		border-color: $color-map-grey;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-grey),to($color-map-grey));
		background-color: linear-gradient(to bottom, $color-map-grey, $color-map-grey);
	}

	.c-btn--secondary-gradient {
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-grey),to($color-map-dark-grey));
			background-color: linear-gradient(to bottom, $color-map-grey, $color-map-dark-grey);
		}
	}

	.c-btn--warning {
		background-color: $color-map-yellow;
		border-color: $color-map-yellow;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-yellow),to($color-map-yellow));
		background-color: linear-gradient(to bottom, $color-map-yellow, $color-map-yellow);
	}

	.c-btn--warning-gradient {
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-yellow),to($color-map-dark-yellow));
			background-color: linear-gradient(to bottom, $color-map-yellow, $color-map-dark-yellow);
		}
  }

	.c-btn--orange {
		background-color: $color-map-orange;
		border-color: $color-map-orange;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-orange),to($color-map-orange));
		background-color: linear-gradient(to bottom, $color-map-orange, $color-map-orange);
	}

	.c-btn--orange-gradient {
		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-orange),to($color-map-dark-orange));
			background-color: linear-gradient(to bottom, $color-map-orange, $color-map-dark-orange);
		}
  }

	.c-btn--pink {
		border-color: $color-map-dark-pink;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-pink),to($color-map-dark-pink));
		background-color: linear-gradient(to bottom, $color-map-dark-pink, $color-map-dark-pink);
	}

	.c-btn--pink-gradient {
		border-color: $color-map-dark-pink;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-pink),to($color-map-dark-pink));
		background-color: linear-gradient(to bottom, $color-map-dark-pink, $color-map-dark-pink);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-pink),to($color-map-pink));
			background-color: linear-gradient(to bottom, $color-map-dark-pink, $color-map-pink);
		}
	}
}

.map-dd-btn-trigger {
  width: 7.2rem;

  &:hover {
		border-color: $color-map-dark-blue-1;
  }
}

.map-dd-trigger {
  &:hover {
    border-color: $color-map-dark-blue-1;
  }
}

.c-table__cell > .ember-basic-dropdown-trigger--below {
  border: 2px solid $color-map-black !important;
  font-weight: 500;
	background: -webkit-gradient(linear,left top,left bottom,from($color-map-white),to($color-map-white));
	background-color: linear-gradient(to bottom, $color-map-white, $color-map-white);
}

.c-table__cell > .ember-basic-dropdown-trigger--above {
  border: 2px solid $color-map-black !important;
  font-weight: 500;
	background: -webkit-gradient(linear,left top,left bottom,from($color-map-white),to($color-map-white));
	background-color: linear-gradient(to bottom, $color-map-white, $color-map-white);
}

.map-content-btn-bootstrap-feel {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: none;
  width: 7.2rem;
  margin: 0;
  padding: 0;
  border: 0;
  border: 2px solid $color-map-black;

  tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .c-dropdown__item {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    min-width: 6.5rem;

    &:hover {
      background-color: $color-map-white;
    }
  }

  tr:not(:last-child) {
    td {
      .dropdown-item {
        border-bottom: 1.5px solid $color-map-dark-grey;
      }
    }
  }

  &.map-content-btn-bootstrap-feel--single-line {
    width: auto;
  }
}

.ember-basic-dropdown-content--above {
  margin-top: 3px;
}

.ember-basic-dropdown-content--below {
  margin-top: -3px;
}

.map-listings-content-product-state-bootstrap-feel {
  .dropdown__container {
    width: 186px;
  }
}

.map-dropdown__button-platform {
  &.map-dropdown__button-platform--small {
    width: 200px;
  }

  &.map-dropdown__button-platform--medium {
    width: 230px;
  }

  &.map-dropdown__button-platform--large {
    width: 250px;
  }

  &.map-dropdown__button-platform--largest {
    width: 280px;
  }
}

.map-dropdow__button-product-state {
  width: 198px;
}

.map-accounts-content-type-bootstrap-feel {
  .dropdown__container {
    width: 8.25rem;
  }
}

.map-accounts-content-unknown-bootstrap-feel {
  .dropdown__container {
    width: 10rem;
  }
}

.map-content-warning-type-bootstrap-feel {
  .dropdown__container {
    width: 210px;
  }
}

.map-dropdown__button-warning-type {
  width: 222px;
}

.map-content-status-bootstrap-feel {
  .dropdown__container {
    width: 9.85rem;
  }
}

.map-content-compliant-status-bootstrap-feel {
  .dropdown__container {
    width: 164px;
  }
}

.map-dropdown__button-compliant-status {
  width: 176px;
}

.map-dropdown__button-download {
  width: 142px;
}

.map-content-select-retailer-bootstrap-feel {
  .dropdown__container {
    width: 11rem;
  }
}

.map-content-size-bootstrap-feel {
  .dropdown__container {
    width: 128px;
  }
}

.map-dropdown__button-size {
  width: 140px;
}

.map-content-key-item-bootstrap-feel {
  .dropdown__container {
    width: 8.8rem;

    .dropdown__item {
      cursor: pointer;
    }
  }
}

.map-dropdown__button-stage {
  width: 160px;
}

.map-content-stage-bootstrap-feel {
  .dropdown__container {
    width: 148px;

    .dropdown__item {
      cursor: pointer;
    }
  }
}



.content-partner-bootstrap-feel {
  width: 146px;
}

.content-platform-bootstrap-feel {
  width: 212px;
}

.content-account-type-bootstrap-feel {
  width: 180px;
}

.content-account-status-bootstrap-feel {
  width: 188px;
}

.c-avatar__img--cover {
  object-fit: cover;
}

.dropdown--flex {
  display: flex;
  align-items: center;

  .c-navbar__title_left_side {
    padding: 0;
  }

  .c-avatar__img {
    margin-right: 5px;
    margin-left: 20px;
  }
}

.c-close {
  position: absolute;
  top: 5px;
  right: 20px;
}

.account-modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.listing-dropdown__image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .sizeToFitImage {
    max-height: 240px;
    object-fit: cover;
  }
}

.container-fluid {
  & > .row {
    & > .col-sm-12 {
      & > div {
        max-height: 85vh;
        overflow: auto;
        border-collapse: separate;

        table.sticky-table-head {
          border-collapse: separate;
          border-spacing: 0;
          border: 0;

          caption {
            position: sticky;
            top: 0;
            z-index: 2;
            border: 0;
          }

          thead {
            position: sticky;
            z-index: 2;

            th {
              border-top: 1.5px solid #E6EAEE;
              border-bottom: 1px solid #E6EAEE;

              &:first-child {
                border-left: 1px solid #E6EAEE;
              }

              &:last-child {
                border-right: 1px solid #E6EAEE;
              }
            }
          }
        }
      }
    }
  }
}
.zero_button {
  padding-right: 10px;
}
.display_text {
  padding-left: 50px;
  color: red;
  font-weight: bold;
}
.account-upload-map-price-list-modal {
  .modal__header {
    font-family: "acumin-pro";
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .condensedTable {
    max-height: 70vh;
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;

    & > :not(:last-child) {
      margin-right: 10px;
    }
  }
}

.account-map-price-display-modal {
  .modal__header {
    font-family: "acumin-pro";
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .modal__body {
    max-height: 70vh;
    overflow: auto;
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;

    & > :not(:last-child) {
      margin-right: 10px;
    }
  }
}

.o-page {
  display: flex;
  margin-left: 0;

  & > div:first-child, .main-navbar {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .o-page__sidebar {
    position: relative;
  }

  .o-page__content {
    margin-left: 0;
    width: 100%;
  }
}

.application-test-environment__title {
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 5px;
  position: relative;
  background-color: $color-map-black;
  justify-content: center;

  & > p, .application-test-environment__link {
    color: $color-map-white;

    &:first-child {
      margin-right: 5px;
    }
  }

  @media print {
    display: none;
  }
}

.c-table__cell__title_listings {
  min-width: 300px;
  max-width: 600px;
}
