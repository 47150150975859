.search-filter-input {
  .c-navbar__search {
    width: 180px !important;
    .c-field__addon {
      border: 0;

      .c-btn--search-option {
        width: 90px;
        padding-left: 0;
        padding-right: 25px;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .c-field__icon {
      right: 15px;

      &.is-active {
        color: #1090f1;
      }
    }
  }

  .has-addon-right {
    width: 320px !important;

    .c-input {
      border-right: 0;
    }

    .c-field__icon {
      right: 100px;
    }
  }

  .has-multi-field {
    display: flex;
    width: auto !important;

    .c-input {
      max-width: 180px;
    }

    & > :not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    & > :not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.map-content-search-bootstrap-feel {
  .dropdown__container {
    width: 130px !important;
  }
}
