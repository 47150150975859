.d-none {
	display: none!important;
}

@media (max-width: 991px) {
	.d-none--md {
		display: none;
	}
}

.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

.no-box-shadow {
	box-shadow: none;
}

.p-0 {
	padding: 0;
}

.m-0 {
	margin: 0;
}
