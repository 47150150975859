.seller-white-list-page {
  .page__navbar {
    font-family: "acumin-pro";
    margin-right: 10px;

    .c-navbar__title {
      text-transform: capitalize;
      font-weight: lighter;
    }
  }

  .seller-white-list-table {
    .seller-white-list-table__caption {
      border: 1px solid #E6EAEE;
      border-bottom: 0;

      .seller-white-list-table__caption__section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
      }

      .c-table__title {
        border: 0;
      }

      .seller-white-list-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .seller-white-list-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .seller-white-list-table__header {
      top: 86px;
    }
  }
}
