.map-product-display-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 80vw;

  .modal__header {
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin: 0;
    }
  }

  .modal__body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 70vh;
    overflow: auto;

    .list-type {
      margin-bottom: 15px;
      .list-type__info-title {
        text-align: center;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-btn {
        min-width: 153px;
      }
    }

    .c-table__cell {
      max-width: 200px;
      word-break: break-all;
    }
  }

    .modal__actions {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .c-btn {
        min-width: 100px;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
}
