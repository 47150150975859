.sellers-page {
  .sellers-table {
    .sellers-table__caption {
      border: 1px solid #E6EAEE;
      border-bottom: 0;

      .sellers-table__caption__section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: start;
        background-color: #fff;

        &.sellers-table__caption--top {
          border-bottom: 1px solid #E6EAEE;
        }
      }

      .c-table__title {
        border: 0;
      }

      .sellers-table__caption__title {
        margin: 0;
        border: 0;
      }

      .sellers-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .sellers-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      .seller-new-table-filter{
        gap: 8px;
      }
    }

    .c-table__cell__stage-date {
      font-size: .75rem;
    }

    .c-table__cell {
      & > .c-badge {
        white-space: nowrap;
      }

      & > .c-badge.c-badge--primary {
        margin-bottom: 5px;
      }
    }

    .sellers-table__header {
      top: 200px;
    }
  }
}

@media (max-width: 1300px) {
  .sellers-page {
    .sellers-table {
      .sellers-table__caption {
        .sellers-table__caption__section {
          justify-content: flex-start; 
        }
      }
    }
  }
}