*{
    font-family: 'Inter', sans-serif;
  }

  .account-report-page2 {
    font-family: "acumin-pro";
    height: 100vh;
    overflow: auto;
    background-color: rgb(61,61,72);
    font-size: 10px;

    & > div {
      height: 100%;
      max-width: 1276px;
      padding: 0;
      margin: 0 auto;

      @media print {
        height: 80%;
      }

      .account-report__header2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        background: #001D3D;
        padding: 10px;

      }

      .account-report__content2 {
        background: white;
        display: flex;
        flex-direction: column;
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 3.5em;
        padding-bottom: 1.6em;

        @media print {
          padding-left: 2em;
          padding-right: 2em;
        }

        & > .row, .col {
          font-family: interface, sans-serif;
          flex-wrap: nowrap;

          & > .col__image {
            max-width: 110px;
            margin-top: 15px;
          }
        }

        & > .row:last-child {
          min-height: 318px;
        }

        .col {
          padding: 0;
          margin: 0 .75em;

          & > .c-divider {
            margin-bottom: 2.5em;
            margin-top: 2.5em;
          }
        }

        .card__title {
          font-size: 1.7em;
          font-weight: 700;
          margin-bottom: 15px;
          line-height: 23.8px;
        }

        .c-state-card {
          display: flex;
          justify-content: space-between;
          height: 80%;
          max-height: 150px;
          padding: 30px 0 30px 20px;
          margin: 0;
          border: 0;
          border-radius: 0;

          & > .c-state-card__content {
            width: 70%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: flex-start;
            line-height: 14px;
            height: 100%;

            .card-content__info {
              display: flex;
              justify-content: space-between;
              width: 100%;

              .card-content__info-type {
                text-transform: uppercase;
                letter-spacing: normal;
                font-weight: 700;
                font-size: 1em;
              }

              .card-content__info-title {
                letter-spacing: .5px;
                font-weight: 700;
                font-size: 1.5rem;
                margin-bottom: 15px;
              }

              .card-content__info-value {
                font-size: 1.3em;
              }

              &:not(:last-child) {
                margin-bottom: 1em;
              }
            }

            &.c-state-card__content--full-width {
              width: 100%;
            }
          }

          &.c-state-card__graph {
            padding: 0;
          }

          & > .c-state-card__image {
            img {
              height: 100px;
            }
          }

          &.c-state-card--secondary {
            background-color: #EFF3F6;
          }

          &.c-state-card--primary {
            background-color: #001D3D;
            color: #EFF3F6;
            padding: 3em;
            max-height: initial;
            min-height: 280px;

            .card-content__info-type {
              font-size: .75rem !important;
              font-weight: 400 !important;
              text-transform: none !important;
            }
          }
        }
      }

      .account-report__footer2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: #001D3D;
        padding: 5px 80px;

        img {
          height: 45px;
        }

        .footer__contact-text {
          font-size: 1.6em;
          padding-top: 1em;
          padding-bottom: 1em;
          color: white;
        }
      }
    }

    &.account-report-page--test {
      height: calc(100vh - 30px);
    }

    @media print {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
      overflow: hidden;


      &.account-report-page--test {
        height: 100vh;
      }
    }

    .new-header{
      color: white;
      font-size: 36px;
      text-align: center;
      font-weight: bold;
      padding-top: 0px;
    }

    .new-date{
      color: white;
      font-size: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    // .new-monthly-div{
    //   width: auto;
    // }

    .new-monthly-div-wrapper-3 {
      background-color: #EDF6FF;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 310px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -54px;
    }

    .new-monthly-div-wrapper-1 {
      background-color: #EDF6FF;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 310px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .new-monthly-div-wrapper-2{
      background-color: #EDF6FF;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
    }



    .new-monthly-boxes-closed {
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 230px;
      border-radius: 15px;
      width: 400px;
      justify-content: space-evenly;
    }


    .new-monthly-boxes {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 11px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 230px;

    }


    .new-monthly-boxes-2 {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 15px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 236px;

    }

    .column-3{
      display: flex;
      flex-direction: column;
    }

    .new-monthly-boxes-3 {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 120px;
      border-radius: 15px;
      flex-wrap: wrap;
      width: 437px;
      justify-content: center;
    }

    .new-monthly-boxes-4 {
      background-color: white;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 286px;
      border-radius: 15px;
      width: 437px;

    }


    .new-monthly-boxes-5 {
      background-color: white;
      display: grid;
      grid-template-columns: repeat(2, 2fr);
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 190px;
      border-radius: 15px;
      width: 300px;

    }

    .new-monthly-boxes-6 {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 15px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 390px;
      height: 270px;
      margin-left: 6px;

    }

    .new-monthly-boxes-6-6 {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 15px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 375px;
      height: 270px;
      margin-left: -16px;

    }


    .data-3{
      font-size: 50px;
      font-weight: bold;
      // padding-left: 47px;
    }


    .text-3{
      font-size: 16px;
      color: #001D3D;
      font-weight: bold;
      // padding-left: 10px;
    }

    .data-4{
      font-size: 50px;
      font-weight: bold;
      // padding-left: 47px;
    }


    .text-4{
      font-size: 16px;
      color: #001D3D;
      font-weight: bold;

      // padding-left: 10px;
    }

    .new-monthly-boxes div {
      text-align: center;
    }

    .new-column-1-closed{
      font-size: 60PX;
      padding-top: 0px;
      font-weight: bold;
      color: #001D3D;
    }

    .new-column-1-all-time-closed{
      font-size: 44PX;
      font-weight: bold;
      color: #001D3D;
      padding-top: 0px;
    }


    .new-column-1{
      display: flex;
      justify-content: center;
      font-size: 70PX;
      padding: 10px;
      font-weight: bold;
      color: #001D3D;
      
      ol{
        ul{
          li{
            white-space: nowrap;        /* Prevent text from wrapping to the next line */
            overflow: hidden;           /* Hide the overflowed text */
            text-overflow: ellipsis;
            max-width: 23ch;
          }
        }
      }
    }

    .new-column-1A{
      font-size: 44PX;
      font-weight: bold;
      color: #001D3D;
    }

    .new-context-1-closed{
      font-size: 22px;
      color: #F4420A;
      margin-top: 0px;
      padding-bottom: 10px;
      font-weight: bold;
      line-height: 20px;
    }

    .new-context-1{
      font-size: 22px;
      color: #F4420A;
      margin-top: 0px;
      padding-bottom: 0px;
      font-weight: bold;
    }

    .new-context-5{
      font-size: 24px;
      color: #F4420A;
      margin-top: 0px;
      padding-bottom: 0px;
      font-weight: bold;
    }

    .new-context-2{
      font-size: 20px;
      color: #F4420A;
      margin-top: 0px;
      padding-bottom: 0px;
      font-weight: bold;
      line-height: 21px;
    }

    .new-context-2A{
      font-size: 22px;
      color: #F4420A;
      margin-top: 8px;
      padding-bottom: 0px;
      font-weight: bold;
      line-height: 24px;
    }

    .new-context-22{
      font-size: 16px;
      color: #F4420A;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .new-context-3{
      font-size: 20px;
      color: #F4420A;
      padding-left: 20px;
    }

    .new-context-4{
      font-size: 20px;
      color: #001D3D;
      font-weight: bold;
      padding-top: 5px;
    }

    .new-context-44{
      font-size: 16px;
      color: #001D3D;
      font-weight: bold;
      padding-top: 5px;
    }

    .gray-span {
      display: block;
      width: 250px; /* Adjust the width as needed */
      height: 2px;
      background-color: gray;
      margin: 0 auto; /* Center the element horizontally */
    }


    .new-month-date{
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .new-all-time-activity-div {
      display: block;
      width: auto;/* Adjust the width as needed */
      height: 80px;
      background-color: #001D3D;
      padding-bottom: 20px; /* Center the element horizontally */

    }

    .new-all-time-activity-text {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 46px;
      color: white;
      font-weight: bold;
    }

    .new-all-time-activity-bg{
      background-color: #FFE0D7;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
    }

    .column-1 {
      flex: 1;
      margin: 10px;
    }

    .column-1-1{
      display: flex;
      flex-direction: row;
      padding-top: 0px;
      margin-left: 0px;
    }

    .column-1-1-1{
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      margin-left: 0px;
    }

    .pie-chart{
      height: 100px;
      margin-right: 10px;
    }

    .year-chart{
      margin-left: 20px;
      height: 150px;
      width: 290px;
    }

    .pie-chart-data-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    .span-data {
      margin-left: 5px; /* Adjust the margin as needed */
      font-size: 15px
    }

    .logo-img-2{
      padding: 5px;
      height: auto;
      width: auto;
      background-color: white;
      border-radius: 10px;
    }

    .result{
      padding-top: 50px;
      padding-bottom: 40px;
      font-size: 17px;
      font-weight: bolder;
    }

    .new-logo-1{
        height: 179px;
        width: 190px;
        margin-left: 24px;
        margin-right: 150px;
    }


    .new-monthly-boxes-6-6-6{
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 260px;
      //height: 141px;
      margin-left: 77px;


    }

    .header-text-container{
      //padding-left: 81px;
      //padding-right: 80px;
      display: flex;
      flex-direction: row;
    }

    .new-monthly-boxes-all-time-closed{
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 230px;
      border-radius: 15px;
      width: 400px;

    }

    .new-monthly-boxes-removed {
      background-color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      margin: 10px;
      height: 239px;
      border-radius: 15px;
      width: 400px;
      justify-content: space-evenly;
      margin-top: 15px;
      margin-left: 2px;
    }

    .new-monthly-boxes-removed-2{
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 11px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 403px;
      height: 242px;
      margin-top: 14px  ;
    }

    .new-monthly-boxes-reviewed{
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 11px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 237px;
      margin-top: 15px;
      margin-left: 1px;
    }

    .new-monthly-boxes-reviewed-2{
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 11px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 237px;
      margin-top: 15px;
    }

    .new-monthly-boxes-infringers{
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 15px;
      justify-content: space-between;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 230px;
    }

    .new-monthly-boxes-infringers-2{
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: 15px;
      border-radius: 10px;
      justify-content: center;
      text-align: center;
      width: 400px;
      height: 236px;
      margin-left: 12px;

    }

    .top-listings{
      font-size: 20px;
      font-weight: bolder;
      color: #001D3D;
      display: block;

    }

    .top-infringers{
      font-size: 19px;
      font-weight: bolder;
      color: #001D3D;
    }

    .marketplaces{
      font-size: 16px;
      color: #F4420A;
      font-weight: bold;
      line-height: 7px;
    }

    .new-context-1-1{
      font-size: 22px;
      color: #F4420A;
      font-weight: bold;
    }

    .text-wrapper{
      margin-top: 0px;
    }

    .data-wrapper{
      margin-top: 0px;
    }
    .ol-class{
      font-weight: bold;
    }

    .new-column-1-1{
      font-size: 70PX;
      font-weight: bold;
      color: #001D3D;
      height: 110px;

    }

    .marketplaces-1{
      line-height: 15px;
      color: #F4420A;
      font-weight: bold;
      font-size: 22px;
    }



}
