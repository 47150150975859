.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .box {
    width: 500px;
    background-color: #fff;
    height: 480px;
    padding: 30px;
    border-radius: 20px;

    .c-avatar__img {
      width: 200px;
      display: block;
      margin: 0 auto;
    }

    .c-btn {
      margin-top: 20px;
    }
  }
}
