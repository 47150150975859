//*{
//    font-family: 'Inter', sans-serif;
//}

.clickup-dashboard {
    padding: 0 0px !important;
    max-height: 100vh;
    overflow: auto;
}
//
//
.header-div-clickup-dashboard{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}
//
.c-state-card-3-clickup-dashboard{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
    border: 1px solid #e6eaee;
    background-color: #fff;
    border-radius: 11px;
    flex-direction: column;
}
//
.welcome-clickup-dashboard{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    padding-left: 30px;
    font-weight: 700;
}
//
.orange-line-clickup-dashboard {
    height: 20px;
    width: 100%;
    background-image: linear-gradient(90deg, #F92D12 0%, #ED6714 61.46%);
    border-radius: 10px 10px 0px 0px; 
    border: solid 1px white;
}
//
.black-line-clickup-dashboard{
    height: 1px;
    width: 100%;
}
//
.table-line-clickup-dashboard{
    height: 1px;
    width: 100%;
}
//
//
//
.map-title-div-clickup-dashboard{
    background: #042441;;
    width: 100%;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
    height: auto;
}

.map-title-div-clickup-dashboard p{
    font-size: 60px;
    color: #e6eaee;
    font-weight: bolder;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    // color: #F92D12;
    // text-shadow: 0 0 10px rgb(255, 99, 2);
    -webkit-text-stroke: 0.4px #ffffff;
}

.c-state-card-1-1-clickup-dashboard{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
    padding: 10px;
    border: 1px solid #e6eaee;
    background-color: #fff;
    border-radius: 11px;
    flex-direction: column;
    height: auto;
}
//
//
.c-state-card-1-1-1-clickup-dashboard{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
    padding: 10px;
    // border: 1px solid #e6eaee;
    background-color: #fff;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
    flex-direction: column;
    height: 720px;
    padding-top: 0px;
}
//
.c-state-card-1-1-1-clickup-dashboard thead tr.table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f2f2f2;
    font-weight: bold;
  }
//
.chat-wrapper-clickup-dashboard{
    background-color: #08acec;
}
.chatbox-clickup-dashboard{
    height: 120px;
}
//
.chat-clickup-dashboard{
    padding: 10px;
    border: 1px solid #e6eaee;
    background-color: #fff;
    height: 702px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 20px;
}
//
.chatbox-clickup-dashboard .chat-incoming-clickup-dashboard{
    display: flex;
}
//
.chatbox-clickup-dashboard .chat-outgoing-clickup-dashboard{
    display: flex;
}
//
.chatbox-clickup-dashboard .chat-outgoing-clickup-dashboard{
    margin-top: 20px;
    justify-content: flex-end;
}
//
//
.chatbox-clickup-dashboard .chat-incoming-clickup-dashboard i{
    height: 32px;
    width: 32px;
    text-align: center;
    background-color: #042441;
    line-height: 32px;
    border-radius: 4px;
    color: #fff;
    margin: 0 10px 7px 0;
    align-self: flex-end;
}
//
.chatbox-clickup-dashboard .chat-incoming-clickup-dashboard p{
    background-color: #ffffff;
    border-radius: 10px 10px 10px 0px;
    padding: 10px;
    color: #042441;
    border: solid 1px #042441;
}
//
.chatbox-clickup-dashboard .chat-outgoing-clickup-dashboard p{
    background-color: #042441;
    border-radius: 10px 10px 0px 10px;
    padding: 10px;
    color: white;
}
//
//
.chatbox-clickup-dashboard .chat-input-clickup-dashboard{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 5px 20px;
    border-top: 4px solid #042441
}
//
.chat-input-clickup-dashboard textarea{
    height: 79px;
    width: 100%;
    margin-top: 466px;
    border: none;
    outline: none;
    font-size: 0.95rem;
    resize: none;
    padding: 15px 15px 15px 0;
    border-top: 1px solid #042441;
}
//
.chat-input-clickup-dashboard i{
    align-self: flex-end;
    height: 55px;
    line-height: 55px;
    color: #042441;
    font-size: 1.35rem;
    cursor: pointer;
    visibility: hidden;
}
//
.chat-input-clickup-dashboard textarea:valid ~ i {
    visibility: visible;
}

.chat-input-clickup-dashboard{
    display: flex;
    flex-direction: row;
}
//
//
.chat-user-clickup-dashboard{
    max-height: 316px;
}
.chat-clickup-dashboard .section-name{
    background-color: rgb(0, 79, 163);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    color: white;
}
//
.chart-clickup-dashboard{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
    padding: 10px;
    border: 1px solid #e6eaee;
    background-color: #fff;
    border-radius: 11px;
    flex-direction: column;
}
//
.content-clickup-dashboard{
    text-align: center;
    margin: 0 auto;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;

}
//
.content-clickup-dashboard p{
    font-size: 20px;
    width: auto;
    background-color: #F92D12;
    border-radius: 5px;
    margin: 10px;
    color: white;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;

}
//
.quick-links-clickup-dashboard {
    text-decoration: none !important;
    color: #fff;
  }
//
.quick-links-clickup-dashboard:hover {
    color: #fff; /* Set your desired hover color */
}
//
.quick-links-2-clickup-dashboard{
    text-decoration: none !important;
    color: #042441;
}
//
//
.quick-links-2-clickup-dashboard:hover {
    color: #042441; /* Set your desired hover color */
}
//
//
.content-2-clickup-dashboard{
    text-align: center;
    margin: 0 auto;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
//
.content-2-clickup-dashboard p{
    font-size: 17px;
    width: auto;
    background-color: #e6eaee;
    border-radius: 5px;
    margin: 10px;
    color: #042441;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}
//
.table-header-clickup-dashboard{
    width: 100%;
    background-color: #042441;
    height: 50px;
    color: #fff;
    font-weight: 100;
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: 5px;
}
//
.table-header-clickup-dashboard th{
    // text-align: left;
    font-size: 15px;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}
//
//
//
.team-clickup-dashboard {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
//
.table-clickup-dashboard {
    border-collapse: collapse;
    background-color: white;
    // overflow: scroll;

}
//
.td-clickup-dashboard {
font-family:'Inter',sans-serif;
text-align: left;
font-size: 15px;
padding: 10px;
}
//
//
//
.info-clickup-dashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
//
//
.section-header-clickup-dashboard{
    background-color: #fff;
    padding: 10px;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    padding-bottom: 0px;
}
//
.section-name-2-clickup-dashboard{
    font-size: 35px;
    color: #042441;
    font-weight: bolder;

}
//
.section-name-4-clickup-dashboard{
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    background-color: #08acec;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
//
.section-name-5-clickup-dashboard{
    background-color: #ffffff;
    color: #042441;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;



}
//
.status-data-clickup-dashboard{
    text-align: center;
    font-weight: 500;
    color: #000000;
  text-transform: uppercase;
}
.status-data-clickup-dashboard a:link{
  color:#FFFFFF;
}
//
.header-design-left-clickup-dashboard{
    text-align: left;
}
.header-design-right-clickup-dashboard{
    text-align: center;
}
//
.date-data-clickup-dashboard, .comment-data-clickup-dashboard{
    text-align: center;
}
//
//
.sheets-clickup-dashboard{
    color: #042441;
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
}
//
///* Styles for the modal */
.modal-clickup-dashboard {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }

  /* Styles for the modal content */
  .modal-content-clickup-dashboard {
    background: #fff;
    width: 700px;
    height: auto;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 80%;
    text-align: center;
  }
//
  /* Close button */
  .close-clickup-dashboard {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
  }

  .close-clickup-dashboard:hover {
    color: #f00;
  }
//
//
.add-comment-button-clickup-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px auto; /* Center vertically and horizontally */
    transition: background-color 0.3s;
  }
//
//
  ul.comment-list-clickup-dashboard {
    list-style: none;
    padding: 0;
    inline-size: 100%;
    overflow-wrap: break-word;
  }
//
//  /* Style for each list item (li) */
  ul.comment-list-clickup-dashboard li {
    //border: 1px solid #ccc;
    //background-color: #f8f8f8;
    //adding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    inline-size: 100%;
    overflow-wrap: break-word;
  }

  ul.comment-list-clickup-dashboard h3{
    color: #002342;
    font-weight: bold;
    padding-top: 10px;
  }

  ul.comment-list-clickup-dashboard b{
    color: #EB6802;
  }


  .wrapper-clickup-dashboard{
    //width: 500px;
    padding: 20px;
  }

  .listingDetailPopUpNoHeight-clickup-dashboard{
    max-height: 90vh;
    overflow-y: auto;
    //background-color: #FAFAFA;
  }

  .sellerNameSectionCenter-clickup-dashboard{
    text-align: left;
  }

  .sellerNameSectionCenter-clickup-dashboard p{
    font-size: 20px;
    color: #0A0D13;
  }

  .accountInfo-clickup-dashboard{
    text-align: center;
  }

  .accountNameTitle-clickup-dashboard{
    color: #0A0D13;
    font-size: 25px;
    font-weight: bold;
    height: auto;
    padding-top: 5px;
  }

  .dividerLine-clickup-dashboard{
    height: 1px;
    clear: both;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .vendorButtonInput-clickup-dashboard{
    margin-top: 10px;
    text-align: center;
  }

  .sellerUrlFieldClass-clickup-dashboard {
    display: block;
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 17px;
    line-height: 1.5;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #ACACAC;
    border-radius: 10px;
    resize: none; /* Disable resizing */
    overflow: hidden; /* Hide overflowing content */
    white-space: pre-wrap; /* Preserve line breaks and wrap */
}

.upload-clickup-dashboard{
    text-align: left;
    color: #0A0D13;
    font-size: 20px;
}


.uploadFile-clickup-dashboard{
    width: 100%;
    padding: 5px;
    background-color: white;
    border: solid 1px #ACACAC;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    font-size: 17px;
}

.uploadFile-clickup-dashboard img{
    margin-bottom: 10px;
}


.button-wrapper-clickup-dashboard{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;

}

.addComment-clickup-dashboard{
    background-color: #002342;
    padding: 10px;
    border-radius: 15px;
    color: white;
    font-size: 15px;
    width: 100px;
}

.cancel-clickup-dashboard{
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    color: #002342;
    font-size: 15px;
    width: 100px;
}

.file-wrapper-clickup-dashboard{
    background-color: #FAFAFA;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
}

.file-wrapper-clickup-dashboard{
    color: #3276E8;
}

.file-separator-clickup-dashboard{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-name-clickup-dashboard{
    order: 1;
}

.upload-icon-clickup-dashboard{
    order: 2;
}

.file-wrapper-clickup-dashboard p {
    color: #3276E8;
    text-align: left;
    font-weight: bold;
}

@keyframes fadeIn-clickup-dashboard {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Apply the animation to the upload-icon class */
  .upload-icon-clickup-dashboard {
    animation: fadeIn-clickup-dashboard  1s ease-in-out; /* Adjust the duration and timing function as needed */
  }



  .modal-content-clickup-dashboard h6{
    overflow-wrap: break-word;
  }

  .comment-text-header-clickup-dashboard{
    font-size: 20px;
    color: #0A0D13;
    text-align: left;
    padding-top: 20px;
  }


.status-f-wrapper-clickup-dashboard{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
}


.to-do-status-clickup-dashboard{
    padding: 10px;
    color: white;
    border-radius: 10px;
    border: solid 1px #6B8498;
    background-color: #6B8498;
    width: 120px;
    font-weight: bold;
}

.underway-status-clickup-dashboard{
    padding: 10px;
    color: white;
    border-radius: 10px;
    background-color: #F4420A;
    border: solid 1px #F4420A;
    width: 120px;
    font-weight: bold;
}


.complete-status-clickup-dashboard{
    padding: 10px;
    color: white;
    border-radius: 10px;
    background-color: #008844;
    border: solid 1px #008844;
    width: 120px;
    font-weight: bold;
}

.cancel-status-clickup-dashboard{
    padding: 10px;
    color: white;
    border-radius: 10px;
    background-color: #E8C031;
    border: solid 1px #E8C031;
    width: 120px;
    font-weight: bold;
}




//
//
//
//
