.cross-check-sellers-modal {
  font-family: "acumin-pro";
  width: 508px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #eff3f6;
  padding: 21px 0 21px 0;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;
    text-align: center;

    .c-choice {
      font-size: 0.875rem;
    }
  }

  .modal__body__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 305px;
    margin-top: 35px;

    .c-btn {
      width: 137px;
    }

    & > .c-btn:last-child {
      margin-left: 10px;
    }
  }
}
