.map-reports-page {
  .map-reports-page__main-section {
    padding: 0 52px !important;
    max-height: 90vh;
    overflow-y: auto;

    .rowTitle {
      margin-bottom: 10px;
    }

    .rowTitleText {
      margin-bottom: 12px;

      .indexTitles {
        font-size: 0.913rem;
      }

      &.rowTitleText-last {
        margin-bottom: 0;
      }
    }

    .c-state-card {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      border-radius: 6px;
      padding: 30px 0 20px 0;
      height: 120px;
      text-align: center;

      &.c-state-card-left-border {
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-left-width: 2px;
        border-left-color: #001D3D;
      }

      .c-state-card__title {
        font-size: 1rem;
      }

      .c-state-card__link {
        color: #1090f1;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .c-state-card__link--pink {
        color: #FE0A5F;
      }
    }
  }
}

@media (min-width: 768px) {
  .map-reports-page {
    .map-reports-page__main-section {
      padding: 0 26px;
    }
  }
}
