.seller-status-history-wrapper {
    width: 900px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 700px;
}

.seller-status-history-header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white; /* Set a background color if needed */
    z-index: 100; /* Adjust the z-index as needed to layer it above other elements */
    border-radius: 10px;
}

.seller-status-history-header {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #002342;
    margin-bottom: 20px;
    padding-top: 20px;
}


.seller-status-history-close {
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px;
}

.seller-status-history-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.seller-status-history-container::before {
    content: '';
    width: 2px;
    height: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to bottom, #ED6714 0%, #F92D12 100%);
    z-index: 1;
    animation: seller-status-history-drawLine 1.5s ease-out forwards;
}


.seller-status-history-container .seller-status-history-li:nth-child(even)::before {
    content: '';
    position: absolute;
    width: 30px; /* Set the width to 100% for a horizontal line */
    height: 2px; /* Height of the horizontal line */
    left: -30px; /* Set initial position to 0 */
    top: 50%; /* Adjust this value to vertically center the line */
    background: linear-gradient(to right, #ED6714 0%, #F92D12 100%);
    animation: seller-status-history-renderAnimationLeft  2s linear; /* Adjust the duration and animation type as needed */
    z-index: -1;
}

@keyframes seller-status-history-renderAnimationLeft {
    0% {
        width: 0; /* Start with 0 width */
    }
    100% {
        width: 100px; /* Render to full width */
    }
}
@keyframes seller-status-history-renderAnimationLeftToRight {
    0% {
        width: 0; /* Start with full width */
    }
    100% {
        width: 30px; /* Render to 0 width */
    }
}

.seller-status-history-container .seller-status-history-li:nth-child(odd)::before {
    content: '';
    position: absolute;
    width: 30px; /* Set the width to 100% for a horizontal line */
    height: 2px; /* Height of the horizontal line */
    top: 50%;
    left: 400px; /* Adjust this value to align with your vertical line */
    background: linear-gradient(to left, #ED6714 0%, #F92D12 100%);
    animation: seller-status-history-renderAnimationLeftToRight 2s linear; /* Adjust the duration and animation type as needed */
    z-index: -1;
}

@keyframes seller-status-history-drawLine {
    0%, 100% {
        height: 0;
        top: 100%;
    }
    100% {
        height: 100%;
        top: 0;
    }
}




.seller-status-history-ul {
    list-style: none;
    margin-bottom: 10px;
    padding: 20px;
    margin-top: 65px;
}

.seller-status-history-li {
    width: 400px;
    height: auto;
    padding: 15px 20px;
    background-color: rgb(250, 247, 247);
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.384);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
}

.seller-status-history-container .seller-status-history-li:nth-child(even) {
    float: right;
    clear: left;
}

.seller-status-history-container .seller-status-history-li:nth-child(odd) {
    float: left;
    clear: right;
}

.seller-status-history-record{
    font-size: 24px;
    font-weight: bolder;
}

.seller-status-history-modified{
    font-size: 17px;
    color: #002342;
}

.seller-status-history-date{
    font-size: 17px;
    color: #002342;
}

.seller-status-history-current {
    background-color: red;
    display: inline-block;
    width: auto;
    font-size: 15px;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}

.seller-status-history-status-new-seller, 
.seller-status-history-status-rejected-seller, 
.seller-status-history-status-verified-seller, 
.seller-status-history-status-authorized-seller,
.seller-status-history-status-notified-seller,
.seller-status-history-status-review-seller,
.seller-status-history-status-unauthorized-seller,
.seller-status-history-status-re-enforcement-seller,
.seller-status-history-status-enforcement-seller,
.seller-status-history-status-notice-1-seller,
.seller-status-history-status-notice-2-seller,
.seller-status-history-status-notice-3-seller,
.seller-status-history-status-re-notification-seller,
.seller-status-history-status-complete-seller,
.seller-status-history-status-inactive-seller{
    display: inline-block;
    width: auto;
    font-size: 15px;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}
.seller-status-history-status-new-seller {
    background: linear-gradient(to bottom, #1090f1, #1990ea);
}

.seller-status-history-status-rejected-seller {
    background: linear-gradient(to bottom, #475364, #273142);
}

.seller-status-history-status-verified-seller {
    background: linear-gradient(to bottom, #39b54a, #34aa44);
}

.seller-status-history-status-authorized-seller {
    background: linear-gradient(to bottom, #39b54a, #34aa44);
}

.seller-status-history-status-notified-seller {
    background: linear-gradient(to bottom, #F5cc39, #E1BA2D);
}

.seller-status-history-status-review-seller {
    background: linear-gradient(to bottom, #fd9a18, #f16911);
}

.seller-status-history-status-unauthorized-seller {     
    background: linear-gradient(to bottom, #d35847, #db161e);
}



//Stage Designs
.seller-status-history-status-re-enforcement-seller{
    background: linear-gradient(to bottom, #1090f1, #1990ea);
}

.seller-status-history-status-enforcement-seller{
    background: linear-gradient(to bottom, #F5cc39, #E1BA2D);
}

.seller-status-history-status-notice-1-seller{
    background: linear-gradient(to bottom, #187AC6, #1a91eb);
}

.seller-status-history-status-notice-2-seller{
    background: linear-gradient(to bottom, #187AC6, #1a91eb);
}

.seller-status-history-status-notice-3-seller{
    background: linear-gradient(to bottom, #187AC6, #1a91eb);
}

.seller-status-history-status-re-notification-seller{
    background: linear-gradient(to bottom, #187AC6, #1a91eb);
}

.seller-status-history-status-complete-seller{
    background: linear-gradient(to bottom, #187AC6, #1a91eb);
}

.seller-status-history-status-inactive-seller{
    background-color: #475364;
}


.seller-status-history-record-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seller-status-history-li:first-child .seller-status-history-image {
    width: 50px;
    margin-top: -60px;
    margin-left: 87%; /* Center the image horizontally */
    position: absolute;
    animation: seller-status-history-ping 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform: translateX(-50%); /* Center the image horizontally */
}


.seller-status-history-li:not(:first-child) .seller-status-history-image {
    display: none;
}



@keyframes seller-status-history-ping {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}


