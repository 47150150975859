.o-page__sidebar {
  width: 144px;
  padding: 0;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-right-width: 0;
  }

  .c-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 144px;
    height: 100vh;
    padding: 31px 0 25px 0;
    background-color: #001D3D;

    .c-sidebar__list--mt {
      margin-top: 20px;
    }

    .c-sidebar__body {
      .c-sidebar__brand {
        display: flex;
        justify-content: center;

        .c-sidebar__brand-img {
          margin: 0;
          width: 68px;
          height: 68px;
        }
      }

      .c-sidebar__list {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, .10);

        .c-sidebar__item {
          i {
            width: 14px;
            text-align: center;
          }

          .c-sidebar__link {
            color: rgba(255, 255, 255, .4);

            &.active, &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    .c-sidebar__footer {
      margin-top: 30px;

      .sideBarLogoImage2 {
        display: block;
        margin: 0 auto;
        width: 124px;
      }
    }
  }
}

// for the map based sidebar
.main-navbar {
  .o-page__sidebar {
    .c-sidebar__body {
      .c-sidebar__list {
        .c-sidebar__item {
          .c-sidebar__link {
            padding: 10px 30px;
            line-height: 15px;
          }
        }
      }
    }
  }
}

// for the existing sidebar
.o-page__sidebar {
  .c-sidebar__body {
    .c-sidebar__list {
      .c-sidebar__item {
        .c-sidebar__link {
          padding: 10px 5px 10px 30px;
          line-height: 15px;
        }
      }
    }
  }
}
