.send-notice-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 500px;
  min-height: 530px;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 25px;

    .form {
      .c-field__label {
        color: #001D3D;
      }

      .c-field {
        margin-bottom: 15px;
      }

      .c-textarea {
        height: 156px;
      }
    }

    .c-divider {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .modal__body__actions {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 305px;
      margin-top: 25px;

      .c-btn {
        width: 137px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
