///* ========================================================================
//   #TABLES
//   ======================================================================== */

$table-border-width: 1px;
$table-border-color: #E6EAEE;

$table-border-radius: $base-border-radius;
$table-background-color: $white;


$table-head-slim-background-color: #F5F8FA;
$table-head-cell-padding: 10px 5px 10px 5px;


$table-cell-padding: 20px 5px 20px 5px;
$table-cell-color: #354052;
$table-cell-font-size: $text-font-size;
$table-cell-font-weight: $font-weight-semibold;
