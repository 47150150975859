.add-new-product-display-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 80vw;

  .modal__header {
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .list-type {
      margin-top: 15px;
      margin-bottom: 15px;
      .list-type__info-title {
        text-align: center;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-btn {
        min-width: 153px;
      }
    }

    .modal__body__actions {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .c-btn {
        min-width: 100px;

        &:first-child {
          margin-right: 10px;
        }
      }

      .confirm-btn {
      }
    }
  }
}
