.notification {
  padding: 0;

  & > :first-child {
    display: none;
  }

  & > :nth-child(2) {
    display: flex;
    justify-content: flex-start;

    i, div {
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .notificationMessage {
      margin-right: auto;
    }
  }
}


.error-notification {
  background-color: #ed1c24;
}


.success-notification {
  background-color: #1bb934;
}


.info-notification {
  background-color: #2ea1f8;
}


.warning-notification {
  background-color: #fd9a18;
}
