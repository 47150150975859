.view-seller-alias-modal {
  font-family: "acumin-pro";
  width: 70vw;
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 6px;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    padding: 70px 32px 0 32px;
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 13px;
      width: 100%; /* Ensure the element takes up full width */
    }
    
    
    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }


}

.content-seller-status-bootstrap-feel {
  .dropdown__container {
    width: 7.6rem;

    .dropdown__item {
      padding: 5px 0 5px 0;
      cursor: pointer;
    }
  }
}
