.account-switcher {
  .dropdown-toggle {
    &:hover {
      text-decoration: none;
    }

    .collection {
      display: none;
    }

    & > div {
      display: flex;
      justify-content: space-between;

      .dropdown-header__title {
        font-weight: 400;
        margin: 0;
        font-size: 17px;
        padding: 0;
        align-self: center;
      }

      .dropdown-header__image {
        object-fit: cover;
        margin-right: 5px;
        margin-left: 20px;
      }
    }
  }

  .dropdown-menu {
    left: inherit!important;
    right: -95px;
    width: 500px;
    border: 1px solid #DFE3E9;
    transform: translate3d(-92px, 36px, 0px)!important;

    .collection {
      width: 100%;
      position: relative;

      .search-input {
        height: 60px;
        padding: 10px;
        border-bottom: 1px solid #F7F7F7;
      }

      .inner-collection {
        width: 100%;
        min-height: auto;
        text-align: center;

        & > div {
          position: relative;
          height: auto;
          width: 500px;

          & > div {
            position: relative;
            overflow-x: hidden !important;
            overflow-y: auto !important;
          }
        }

        &.inner-collection--row-0 {
          & > div {
            height: 48px;
          }
        }

        &.inner-collection--row-1 {
          & > div {
            height: 48px;
          }
        }

        &.inner-collection--row-2 {
          & > div {
            height: 80px;
          }
        }

        &.inner-collection--row-3 {
          & > div {
            height: 118px;
          }
        }

        &.inner-collection--row-4 {
          & > div {
            height: 156px;
          }
        }

        &.inner-collection--row-5 {
          & > div {
            height: 192px;
          }
        }

        &.inner-collection--row-6 {
          & > div {
            height: 228px;
            width: 500px;
          }
        }

        li {
          width: 100%;
          text-align: center;
          border-top: 1px solid #F7F7F7;
          padding: 8px 12px;
          position: relative;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            &:hover {
              background-color: transparent;
            }

            .dropdown-item__text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #001D3D;
              text-decoration: none;
              max-width: 500px;
              margin-right: 20px;
            }

            .dropdown-item__text--only-text {
              margin-right: 0;
              max-width: 100%;
            }

            .dropdown-item__icon {
              cursor: pointer;
              position: absolute;
              right: 25px;
              max-width: 25px;
            }
          }
        }
      }
    }
  }
}
