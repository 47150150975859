.show-filters-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 50vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;

      .filter__list {
        max-height: 50vh;

        .filter__item {
          display: flex;
          white-space: nowrap;

          & > :not(:last-child) {
            margin-right: 5px;
          }

          .item__name {
            text-transform: capitalize;
          }

          .item__value {
            color: #999;
          }

          .item__reset {
            position: relative;
            top: auto;
            right: auto;
          }
        }
      }

      .filter__list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  .modal__body__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 305px;
    margin-top: 35px;

    .c-btn {
      width: 137px;
    }
  }
}
