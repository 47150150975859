.seller-listings-page {
  .sub-page__navbar {
    font-family: "acumin-pro";
    margin-right: 10px;

    .c-navbar__title {
      text-transform: capitalize;
      font-weight: lighter;
    }

    .c-navbar__back-button {
      display: flex;
      align-items: center;
      font-weight: lighter;
      cursor: pointer;
      font-size: 16px;

      &::before {
        content: '< ';
        margin-right: 5px;
        margin-bottom: 1px;
      }
    }
  }


  .seller-listings-table {
    .seller-listings-table__caption {
      border: 1px solid #E6EAEE;
      border-bottom: 0;

      .seller-listings-table__caption__section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        &.seller-listings-table__caption--top {
          border-bottom: 1px solid #E6EAEE;
        }
      }

      .c-table__title {
        border: 0;
      }

      .seller-listings-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .seller-listings-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .seller-listings-table__header {
      top: 146px;
    }
  }
}
