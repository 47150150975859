.account-report-page {
  font-family: "acumin-pro";
  height: 100vh;
  overflow: auto;
  background-color: rgb(61,61,72);
  font-size: 10px;

  & > div {
    height: 100%;
    max-width: 840px;
    padding: 0;
    margin: 0 auto;

    @media print {
      height: 80%;
    }

    .account-report__header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #001D3D;
      padding: 10px;
  
      img {
        height: 91px;
      }
    }
  
    .account-report__content {
      background: white;
      display: flex;
      flex-direction: column;
      padding-left: 7em;
      padding-right: 7em;
      padding-top: 3.5em;
      padding-bottom: 1.6em;

      @media print {
        padding-left: 2em;
        padding-right: 2em;
      }

      & > .row, .col {
        font-family: interface, sans-serif;
        flex-wrap: nowrap;

        & > .col__image {
          max-width: 110px;
          margin-top: 15px;
        }
      }

      & > .row:last-child {
        min-height: 318px;
      }

      .col {
        padding: 0;
        margin: 0 .75em;

        & > .c-divider {
          margin-bottom: 2.5em;
          margin-top: 2.5em;
        }
      }

      .card__title {
        font-size: 1.7em;
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 23.8px;
      }

      .c-state-card {
        display: flex;
        justify-content: space-between;
        height: 80%;
        max-height: 150px;
        padding: 30px 0 30px 20px;
        margin: 0;
        border: 0;
        border-radius: 0;

        & > .c-state-card__content {
          width: 70%;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          align-items: flex-start;
          line-height: 14px;
          height: 100%;

          .card-content__info {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .card-content__info-type {
              text-transform: uppercase;
              letter-spacing: normal;
              font-weight: 700;
              font-size: 1em;
            }

            .card-content__info-title {
              letter-spacing: .5px;
              font-weight: 700;
              font-size: 1.5rem;
              margin-bottom: 15px;
            }
  
            .card-content__info-value {
              font-size: 1.3em;
            }

            &:not(:last-child) {
              margin-bottom: 1em;
            }
          }

          &.c-state-card__content--full-width {
            width: 100%;
          }
        }

        &.c-state-card__graph {
          padding: 0;
        }

        & > .c-state-card__image {
          img {
            height: 100px;
          }
        }

        &.c-state-card--secondary {
          background-color: #EFF3F6;
        }

        &.c-state-card--primary {
          background-color: #001D3D;
          color: #EFF3F6;
          padding: 3em;
          max-height: initial;
          min-height: 280px;

          .card-content__info-type {
            font-size: .75rem !important;
            font-weight: 400 !important;
            text-transform: none !important;
          }
        }
      }
    }
    
    .account-report__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: #001D3D;
      padding: 5px 80px;
  
      img {
        height: 45px;
      }
  
      .footer__contact-text {
        font-size: 1.6em;
        padding-top: 1em;
        padding-bottom: 1em;
        color: white;
      }
    }
  }

  &.account-report-page--test {
    height: calc(100vh - 30px);
  }
  
  @media print {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
    overflow: hidden;

    
    &.account-report-page--test {
      height: 100vh;
    }
  }
}
