.last-seen-date-range-modal {
    font-family: "acumin-pro";
    padding: 21px 32px 15px 32px;
    width: 500px;
    min-height: 229px;
  
    .modal__header {
      text-align: center;
  
      .c-close {
        position: absolute;
        top: 5px;
        right: 15px;
      }
  
      .modal-svg {
        margin-bottom: 13px;
      }
  
      .modal-title {
        font-size: 22px;
        font-weight: 300;
      }
    }
  
    .modal__body {
      margin-top: 12px;
  
      .audit-report__info-title {
        font-size: 12px;
        display: block;
      }
  
      .c-btn--outline {
        width: 100%;
        text-align: left;
      }    
  
      .modal__body__actions {
        display: flex;
        justify-content: center;
        margin-top: 25px;
  
        .c-btn {
          width: 137px;
  
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }

    .last-seen-modal-title{
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 36px;
        font-weight: 300;
    }
}
  