.leads-page {
  .leads-table {
    .leads-table__caption {
      border: 1px solid #E6EAEE;
      border-bottom: 0;

      .leads-table__caption__section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        &.leads-table__caption--top {
          border-bottom: 1px solid #E6EAEE;
        }
      }

      .c-table__title {
        border: 0;
      }

      .leads-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .leads-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .leads-table__header {
      top: 146px;
    }
  }
}
