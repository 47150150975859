.show-image-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }
  }

  .modal__body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    max-height: 75vh;
    overflow: hidden;
    position: relative;

    .zoom-container {
      top: auto;
      bottom: 0;
      right: 0;
      font-size: 1.25rem;
      border-radius: 0;
      position: absolute;
      z-index: 2;
      overflow: hidden;

      & > div {
        border-radius: 0;
      }

      .zoomIn, .zoomOut {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 50px;
        height: 50px;
        cursor: pointer;
        background-color: rgba(242, 242, 242, 0.8);
        transition: background-color .3s;

        &:hover {
          background-color: rgba(232, 232, 232, 0.8);
        }
      }

      .zoomIn::after {
        content: "+"
      }

      .zoomOut::after {
        content: "-"
      }
    }
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 305px;
    margin-top: 25px;

    .c-btn {
      width: 137px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
