/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Apply (btn--info) background if `c-btn--{style}` class isn't specified.
 * 2. Align icon (if placed inside button) with text properly.
 * 3. Add dropdown icon/arrow to button with `has-dropdown` class, it depends
 *    on (FontAwesome) icon font so make sure to modify it if you changed your
 *    base icon font.
 * 4. Use `disabled` property on <button> elements, and `is-disabled` class on
 *    <a> elements as they don't support `disabled` property.
 */

.c-btn {
	display: inline-block;
	position: relative;
	max-width: 100%;
	margin: 0;
	padding: $btn-padding;
	transition: all 0.15s ease-in-out;
	border: $btn-border-width solid;
	border-radius: $btn-border-radius;
	border-color: $btn-border-color; /* [1] */
	color: $btn-color; /* [1] */
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	overflow: hidden;
	vertical-align: middle;
	appearance: none;
	user-select: none;
	@include linear-gradient(to bottom, $blue-300, $blue-400); /* [1] */

	&:hover,
	&:focus {
		outline: 0;
		color: $btn-hover-color;
		text-decoration: none;
	}

	i {
		position: relative;
		bottom: -1px; /* [2] */
		font-size: 16px;
	}

	&.has-dropdown {
		position: relative;
		padding-right: 2.5rem;

		&::after { /* [3] */
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			margin-left: 1.25rem;
			padding: 0.5rem 8px;
			border-left: 1px solid;
			border-color: inherit;
			color: inherit;
			font-family: $base-icon-font;
			content: "\f107"; // fa-angle-down
		}
	}

	&.is-disabled,
	&:disabled { /* [4] */
		border-color: $btn-disabled-border-color;
		background: $btn-disabled-background-color;
		color: $btn-disabled-color;

		&:hover,
		&:focus {
			border-color: $btn-disabled-border-color;
			background: $btn-disabled-background-color;
			color: $btn-disabled-color;
			cursor: not-allowed;
		}
	}
}




.c-btn-empty {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: $btn-padding;
  transition: all 0.15s ease-in-out;

  background-color: #FFFFFF;

  border: 1px solid #061D3B;
  border-radius: 3px;
  opacity: 1;

  color: $btn-color; /* [1] */
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  appearance: none;
  user-select: none;

  &:hover,
  &:focus {
    outline: 0;
    color: $btn-hover-color;
    text-decoration: none;
  }

  i {
    position: relative;
    bottom: -1px; /* [2] */
    font-size: 16px;
  }

  &.has-dropdown {
    position: relative;
    padding-right: 2.5rem;

    &::after { /* [3] */
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      margin-left: 1.25rem;
      padding: 0.5rem 8px;
      border-left: 1px solid;
      border-color: inherit;
      color: inherit;
      font-family: $base-icon-font;
      content: "\f107"; // fa-angle-down
    }
  }

  &.is-disabled,
  &:disabled { /* [4] */
    border-color: $btn-disabled-border-color;
    background: $btn-disabled-background-color;
    color: $btn-disabled-color;

    &:hover,
    &:focus {
      border-color: $btn-disabled-border-color;
      background: $btn-disabled-background-color;
      color: $btn-disabled-color;
      cursor: not-allowed;
    }
  }
}




.c-btn--fullwidth {
	width: 100%;
}

/**
 * 1. Reduce spacing between the icon and text in small buttons.
 */

.c-btn--small {
	padding: $btn-small-padding;
	font-size: $btn-small-font-size;

	i {
		margin-right: 5px; /* [1] */
	}
}

.c-btn--large {
	padding: $btn-large-padding;
	font-size: $btn-large-font-size;
}


/* Style variants
   ========================================================================== */

/**
 * Buttons have a simple, nice gradient on the background, we use
 * sass's `darken()` method to generate a dark shade of the requested color,
 * then we use our `linear-gradient` mixin to generate the background gradient.
 */

.c-btn--primary {
	@include linear-gradient(to bottom, $gunmetal-100, $gunmetal-400);
	border-color: $gunmetal-200;

	&:hover {
		@include linear-gradient(to bottom, darken($gunmetal-100, 4%), darken($gunmetal-500, 4%));
	}

	&:focus {
		@include linear-gradient(to bottom, darken($gunmetal-100, 6%), darken($gunmetal-500, 6%));
	}
}

.c-btn--secondary {
	@include linear-gradient(to bottom, $white, $grey-200);
	border-color: $grey-400;
	color: $gunmetal-200;

	&:hover {
		@include linear-gradient(to bottom, darken($white, 1%), darken($grey-200, 2%));
		color: $gunmetal-200;
	}

	&:focus {
		@include linear-gradient(to bottom, darken($white, 2%), darken($grey-200, 3%));
		color: $gunmetal-200;
	}
}

.c-btn--info {
	@include linear-gradient(to bottom, $blue-300, $blue-400);
	border-color: $blue-500;

	&:hover {
		@include linear-gradient(to bottom, darken($blue-300, 4%), darken($blue-400, 4%));
	}

	&:focus {
		@include linear-gradient(to bottom, darken($blue-300, 6%), darken($blue-400, 6%));
	}
}

.c-btn--todo {
  @include linear-gradient(to bottom, #6c8599, #6c8599);
  border-color: #6c8599;

  &:hover {
    @include linear-gradient(to bottom, darken(#6c8599, 4%), darken(#6c8599, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken(#6c8599, 6%), darken(#6c8599, 6%));
  }
}

.c-btn--underway {
  @include linear-gradient(to bottom, #f4420a, #f4420a);
  border-color: #f4420a;

  &:hover {
    @include linear-gradient(to bottom, darken(#f4420a, 4%), darken(#f4420a, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken(#f4420a, 6%), darken(#f4420a, 6%));
  }
}

.c-btn--complete {
  @include linear-gradient(to bottom, #008844, #008844);
  border-color: #008844;

  &:hover {
    @include linear-gradient(to bottom, darken(#008844, 4%), darken(#008844, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken(#008844, 6%), darken(#008844, 6%));
  }
}



.c-btn--success {
	@include linear-gradient(to bottom, $green-300, $green-400);
	border-color: $green-500;

	&:hover {
		@include linear-gradient(to bottom, darken($green-300, 4%), darken($green-400, 4%));
	}

	&:focus {
		@include linear-gradient(to bottom, darken($green-300, 6%), darken($green-400, 6%));
	}
}

.c-btn--stage{
  @include linear-gradient(to bottom, $color-behance, $blue-500);
  border-color: $green-500;

  &:hover {
    @include linear-gradient(to bottom, darken($color-behance, 4%), darken($blue-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($color-behance, 6%), darken($blue-500, 6%));
  }
}

.c-btn--fancy {
	@include linear-gradient(to bottom, $purple-300, $purple-500);
	border-color: $purple-500;

	&:hover {
		@include linear-gradient(to bottom, darken($purple-300, 4%), darken($purple-500, 4%));
	}

	&:focus {
		@include linear-gradient(to bottom, darken($purple-300, 6%), darken($purple-500, 6%));
	}
}

.c-btn--warning {
   @include linear-gradient(to bottom, $orange-300, $orange-500);
   border-color: $orange-500;

   &:hover {
     @include linear-gradient(to bottom, darken($orange-300, 4%), darken($orange-500, 4%));
   }

   &:focus {
     @include linear-gradient(to bottom, darken($orange-300, 6%), darken($orange-500, 6%));
   }
 }


.c-btn--red {
  @include linear-gradient(to bottom, $red-300, $red-500);
  border-color: $red-500;

  &:hover {
    @include linear-gradient(to bottom, darken($red-300, 4%), darken($red-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($red-300, 6%), darken($red-500, 6%));
  }
}

.c-btn--danger {
   @include linear-gradient(to bottom, $red-100, $red-300);
   border-color: $red-300;

   &:hover {
     @include linear-gradient(to bottom, darken($red-100, 4%), darken($red-300, 4%));
   }

   &:focus {
     @include linear-gradient(to bottom, darken($red-100, 6%), darken($red-300, 6%));
   }
 }

.c-btn--yellow {
  @include linear-gradient(to bottom, $yellow-100, $yellow-300);
  border-color: $yellow-300;

  &:hover {
    @include linear-gradient(to bottom, darken($yellow-100, 4%), darken($yellow-300, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($yellow-100, 6%), darken($yellow-300, 6%));
  }
}


.c-btn--purple {
  @include linear-gradient(to bottom, $purple-100, $purple-400);
  border-color: $purple-200;

  &:hover {
    @include linear-gradient(to bottom, darken($purple-100, 4%), darken($purple-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($purple-100, 6%), darken($purple-500, 6%));
  }
}


.c-btn--green {
  @include linear-gradient(to bottom, $green-100, $green-400);
  border-color: $green-200;

  &:hover {
    @include linear-gradient(to bottom, darken($green-100, 4%), darken($green-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($green-100, 6%), darken($green-500, 6%));
  }
}

.c-btn--orange {
  @include linear-gradient(to bottom, $orange-100, $orange-400);
  border-color: $orange-200;

  &:hover {
    @include linear-gradient(to bottom, darken($orange-100, 4%), darken($orange-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($orange-100, 6%), darken($orange-500, 6%));
  }
}


.c-btn--pink{
  @include linear-gradient(to bottom, $pink-100, $pink-400);
  border-color: $pink-200;

  &:hover {
    @include linear-gradient(to bottom, darken($pink-100, 4%), darken($pink-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($pink-100, 6%), darken($pink-500, 6%));
  }
}

.c-btn--black{
  @include linear-gradient(to bottom, $gunmetal-100, $gunmetal-400);
  border-color: $gunmetal-200;

  &:hover {
    @include linear-gradient(to bottom, darken($gunmetal-100, 4%), darken($gunmetal-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($gunmetal-100, 6%), darken($gunmetal-500, 6%));
  }
}



.c-btn--grey{
  @include linear-gradient(to bottom, $gunmetal-100, $grey-400);
  border-color: $grey-200;

  &:hover {
    @include linear-gradient(to bottom, darken($gunmetal-100, 4%), darken($grey-500, 4%));
  }

  &:focus {
    @include linear-gradient(to bottom, darken($gunmetal-100, 6%), darken($grey-500, 6%));
  }
}

