.map-listings-page {
  .map-listings-page__main-section {
    padding: 0 26px;

    .c-badge--nowrap {
      white-space: nowrap;
    }

    .map-listings-table {
      border-radius: 6px;

      .map-listings-table__caption {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border: 1px solid #E6EAEE;
        border-bottom: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .map-listings-table__caption__title {
          margin: 0;
          border: 0;
          font-weight: lighter;
          text-transform: capitalize;
          font-size: 20px;
          color: #001D3D;
        }

        .map-listings-table__filters {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;

          .map-listings-table__filter {
            margin-right: 8px;
            text-transform: capitalize;

            &:last-child {
              margin-right: 0;
            }

            .c-navbar__search {
              width: 180px!important;
            }
          }
        }
      }

      .map-listings-table__header {
        height: 57px;
        background-color: #F2F5FA;

        & > tr {
          & > th {
            color: #001D3D;

            &:last-child {
              padding-right: 19px;
            }

            & > a {
              color: #001D3D;
              text-decoration: none;
              font-size: .875rem;
            }
          }
        }
      }

      .map-listings-table__body {
        & > tr {
          & > td {
            color: #001D3D;
            padding-right: 5px;
            padding-left: 5px;

            &.c-table__cell--danger {
              color: $color-map-pink;
            }

            &:last-child {
              padding-right: 19px;
            }

            &:first-child {
              padding-left: 19px;
            }

            & > .sizeToFitImage {
              width: 60px;
            }

            & > a {
              color: #001D3D;;
            }
          }
        }
      }
    }
  }
}

.dropdown__container {
  &.map-listings-platform-filter {
    .dropdown__item {
      display: flex;
      align-items: center;

      .c-choice {
        margin-right: 10px;
      }
    }
  }
}