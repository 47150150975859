.product-listing-detail-modal {
  font-family: "acumin-pro";
  width: 35vw;
  min-height: 229px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 992px) {
    width: 508px;
  }
  
  .modal__header-download {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background: #001D3D;
    padding: 10px;

    img {
      height: 45px;
    }

    .header-download__text {
      font-size: 1.75rem;
      font-style: italic;
      padding-bottom: 5px;
      color: white;
    }
  }

  .modal__content {
    padding: 0 16px;

    .modal__header {
      text-align: center;
  
      i.fa-download {
        position: absolute;
        top: 12px;
        right: 35px;
        cursor: pointer;
        z-index: 1;
      }
  
      .c-close {
        top: 5px;
        right: 15px;
      }
  
      .modal-svg {
        margin-bottom: 13px;
      }
  
      .modal-title {
        font-size: 22px;
        font-weight: 300;
        margin-top: 15px;
      }
  
      .modal-title__product-img {
        max-width: 210px;
        max-height: 110px
      }
  
      .modal__carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .swiper-container {
          width: 100%;
  
          .carousel__slide {
            width: 100%;
          }
        }
  
        .swiper-button-prev {
          font-size: 1.5rem;
          font-weight: 500;
          background-image: none;
  
          &:before {
            content: "<";
          }
        }
  
  
        .swiper-button-next {
          font-size: 1.5rem;
          font-weight: 500;
          background-image: none;
  
          &:before {
            content: ">";
          }
        }
      }
    }
  
    .modal__body {
      margin-top: 10px;
  
      .product-detail {
        padding: 0;
        .row {
          margin: 0;
          .col {
            padding: 0;
          }
  
          .col-sm {
            padding: 0;
          }
  
          .col-sm:not(:last-child) {
            margin-right: 10px
          }
        }
  
        .product-detail__divider {
          margin-top: 16px;
        }
  
        .product-detail__info-title {
          font-weight: 500;
          font-size: .7rem;
        }
  
        .product-detail__info-input {
          font-size: .7rem
        }
  
        .product-detail__input-textarea {
          display: none;
          min-height: 60px;
        }
  
        .product-detail__info-dropdown {
          .c-input {
            font-size: .7rem;
            background: none;
            text-align: left;
          }
  
          .c-dropdown__menu {
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            transform: translate3d(0px, 55px, 0px) !important;
          }
        }
  
        .product-detail__info--info {
          color: #0AA9FF;
        }
  
        .product-detail__info-input--danger {
          color: #FE0A5F;
        }
  
        .product-detail__info-img {
          max-width: 180px;
          max-height: 18px;
          position: absolute;
          bottom: 8px;
          left: 10px;
        }
      }
  
      .modal__body__actions {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 305px;
        margin-top: 10px;
  
        .visit-btn {
          width: 50%;
        }
      }
    }
  }
  
  .modal__footer-download {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background: #001D3D;
    padding: 10px;

    img {
      height: 45px;
    }

    .footer-download__contact-text {
      font-size: .8rem;
      color: white;
    }
  }
}
