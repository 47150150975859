.add-new-product-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 500px;
  min-height: 229px;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;

    .modal__body__actions {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 305px;

      .c-btn {
        width: 137px;
      }

      .confirm-btn-column {
        position: relative;

        .confirm-btn {
          label {
            color: #FFF;
          }
        }

        .recommended {
          position: absolute;
          top: -20px;
          left: 32px;
          font-size: 10px;
        }

        .download-link {
          position: absolute;
          left: 10px;
          bottom: -35px;
          font-size: 10px;
          text-decoration: underline;
        }
      }

      & > span {
        width: 30px;
        font-size: 12px;
        text-align: center;
        align-self: center;
      }
    }
  }
}
