.email-list-display-modal {
  background-color: #eff3f6;

  .modal__header {
    text-align: center;

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 10px;
    overflow: auto;
    max-height: 40vh;
  }

  .modal__actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    & > :not(:last-child) {
      margin-right: 10px;
    }

    .c-btn {
      min-width: 137px;
    }
  }
}
