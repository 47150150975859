.new-reporting-system-container{
    width: 1080px;
    height: auto;

    .new-reporting-system-title{
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }
}

.new-reporting-system-date-picker-wrapper{
    padding-top: 20px;
}

.new-reporting-system-date-picker-row{
    display: flex;
    justify-content: space-evenly;

    .new-reporting-system-date-picker-col-1{
        display: flex;
        flex-direction: column;
        max-width: 190px;
        border-right: solid 2px #DADADA;

        .new-reporting-system-date-picker-button{
            margin: 10px;
            background-color: transparent;
            border: none;
            font-size: 16px;
            color: black;
            font-weight: 600;

        }
        #new-reporting-system-date-picker-button:active{
            color: #FF8717;
        }
    }

    .new-reporting-system-month-container{
        text-align: center;
        width: auto;
        height: auto;


        .new-reporting-system-month-name-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .new-reporting-system-first-month-left-arrow, 
            .new-reporting-system-first-month-right-arrow {
                border-radius: 6px;
                background: #e0e0e0;
                box-shadow: 0px 0px 4px 1px #b8b8b8, 0px 1px 34px #ffffff;
                width: 22px;
                height: 20px;
                border: none;

            }

            .new-reporting-system-month-name{
                font-size: 28px;
                color: #001D3D;
                font-weight: 300;
            }
        }

        .new-reporting-system-days-name{
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 15px;
            padding-top: 20px;
            padding-bottom: 20px;

            .new-reporting-system-days-name-button{
                font-size: 18px;
                background: transparent;
                border: none;
                color: #0051AA;
                font-weight: bolder;
            }
        }

        .new-reporting-system-days-number{
            display: grid;
            grid-template-columns: repeat(7, 1fr); // 7 columns for the days of the week
            gap: 30px; // Gap between each day

            .new-reporting-system-number-button{
                font-size: 15px;
                background: transparent;
                border: none;
                color: #0051AA;
            }

            .selected-color {
                background-color: #ff0000 !important; /* You can change this to any color you prefer */
                color: white !important;
            }

            .selected-range .new-reporting-system-number-button {
                background-color: #007bff; /* You can change this to any color you prefer */
                color: white;
            }
        }
    }
}

.new-reporting-system-hr{
    height: auto;
    border: 1px solid #DADADA;
}

.new-reporting-system-display-selected-dates{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.new-reporting-system-function-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-right: -160px;

    .new-reporting-system-cancel{
        padding: 2px;
        font-size: 18px;
        border: none;
        width: 70px;
        height: 40px;
        border-radius: 5px;
    }

    .new-reporting-system-apply{
        padding: 2px;
        font-size: 18px;
        border: none;
        width: 90px;
        height: 38px;
        border-radius: 5px;
        background-color: #1D5EFF;
        color: white;

        a{
            color: inherit;
            text-decoration: none;
        }
    }
}


.new-reporting-system-display-wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;

    .new-reporting-system-selected-start-date{
        background-color: #CACACA;
        border: none;
        border-radius: 5px;
        width: auto;
        height: 38px;
        text-align: center;
        font-size: 18px;
    }

    .new-reporting-system-selected-end-date{
        border: solid 1px #FF8717;
        border-radius: 5px;
        width: auto;
        height: 38px;
        text-align: center;
        font-size: 18px;
    }

}