.o-page__content {
  @media (min-width: 992px) {
    margin-left: 144px;
  }

  &.index-page {
    .c-navbar {
      height: 104px;
      padding-top: 10px;
      padding-bottom: 10px;

      .c-navbar__info {
        .c-navbar__title {
          display: flex;
          height: 24px;
          align-items: center;

          .c-avatar {
            margin-right: 10px;
          }
        }
      }

      .c-navbar__crawler-dates {
        font-size: 16px;
        margin-left: 35px;
      }
    }

    .index-page__main-section {
      padding: 0 52px !important;
      max-height: 85vh;
      overflow: auto;

      .row:last-child {
        margin-bottom: 1.875rem;
      }

      .c-state-card {
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
    
        a {
          text-decoration: none;
        }
    
        .c-state-card__content {
          position: relative;
          margin-bottom: 1.875rem;
    
          .c-state-card__numbers {
            display: flex;
            align-items: center;
          }
    
          .c-state-card__number {
            display: flex;
            align-items: center;
            min-height: 60px;
          }
        }
    
        .c-state-card__icon  {
          cursor: pointer;
          position: absolute;
          top: 1.4rem;
          right: 1.875rem;
          margin: 0;
        }
    
        .c-state-card__icon--primary {
          background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-blue-2),to($color-map-dark-blue-2));
        }
    
        & > .c-project__status {
          span {
            color: #7f8fa4;
          }
        }
      }
    }

    .map-content-btn-bootstrap-feel {
      width: auto;
    }
  }
}
.c-navbar__title--pointer {
  cursor: pointer;
}
