.referral-page-title{
    position: relative;
    padding: 25px 30px;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    color: #354052;
    font-size: 24px;
    text-align: left;
    font-weight: 600;
}

.referral-row{
    align-items: center;
    text-align: center;
    padding: 10px;
}