.listings-page-darkmode-toggle-label {
    width: 60px;
    height: 25px;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
    margin-right: 20px;
    border: solid 1px white;
    position: relative;
  }

  .listings-page-darkmode-toggle-label::after {
    content: "";
    width: 19px;
    height: 19px;
    position: absolute;
    top: 2px; /* Change this line */
    left: 4px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    //background: linear-gradient(180deg, #777, #3a3a3a);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    }

  .listings-page-darkmode-toggle-input {
    width: 20px; 
    height: 20px;
    display: none;
  }

  .listings-page-darkmode-toggle-input:checked + .listings-page-darkmode-toggle-label {
    background: #242424;

    &::after {
        left: 55px;
        transform: translateX(-100%);
        background: linear-gradient(180deg, #ffcc89, #d8860b);
        width: 25px;
      }
  }

  .listings-page-darkmode-toggle-input:checked + .listings-page-darkmode-toggle-label::after {
    left: 55px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
    //background: linear-gradient(180deg, #ffcc89, #d8860b);
    width: 20px;
  }

  .darkmode{
    height: auto;
    width: auto;
    //background-color: #3a3a3a;
  }

  .listings-page-darkmode-toggle-input:checked + .listings-page-darkmode-toggle-label + .darkmode-toggle {
    background-color: #d8860b !important;
  }


  .inverted-background-color{
    //background: linear-gradient(to top right, #0D182A, #57637a);
    //background: linear-gradient(to top right, #454b4e, #0a2635);
    background-color: #232526 !important;
    //background: linear-gradient(to top right, #0d1f3f, #224a7e);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Add a subtle box shadow for a glassy effect */
    //backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    border-bottom: 1px solid #1d1f20 !important;

    .o-page__content{
        header{
            .c-navbar__title{
                color: white !important;
            }
        }
    }

    .map-overview-page {
        .map-overview-page__main-section{
            .rowTitleText{
                .indexTitles{
                    color: white !important;
                }
            }
        }
    }  

    .listings-page {
        .listings-table{
            .listings-table__caption{
                .c-table__title{
                    background-color: #232526 !important;
                    color: white;
                }
                .listings-table__caption__section{
                    background-color: #232526 !important;
                }
            }
        }
    }
    
    
    .sellers-page {
        .sellers-table{
            .sellers-table__caption{
                .c-table__title{
                    background-color: #232526 !important;
                    color: white;
                }
                .sellers-table__caption__section{
                    background-color: #232526 !important;
                }
            }
        }
    }

    .sellers-table__body{
        background-color: #232526 !important;
        .c-table__row{
            .c-table__cell{
                p{
                    color: white !important;
                }
            }
        }
    }
    .listings-table__body{
        background-color: #232526 !important;
        .c-table__cell{
            color: white;

            img{
                background-color: white;
            }
        }
    }

    .ember-view .active {
        background-color: #1b1a1a;
    }

    .sellers-table__body{
        .c-table__cell{
            color: white;
        }

        img{
            background-color: white;
        }
    }

    .account-table__body{
        .c-table__cell{
        color: white;
            label{
                color: white;
            }

            img{
                background-color: white;
                height: auto;
                width: auto;
            }
        }
    }

    .account-page{
        .account-table{

            .account-table__body{
                background: linear-gradient(to top right, #292b2c, #1d1f20) !important;   
            }
        
            .account-table__caption{
                .c-table__title{
                    background-color: transparent !important; 
                    color: white;
                }

                .c-table__title small{
                    color: white;
                }


                .account-table__caption__section{
                    background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
                }
            } 
        } 
    } 

    .map-accounts-page{
        .accounts-table__caption{
            background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
        }

        .accounts-table__caption__title{
            background-color: transparent !important; 
            color: white !important;
        }
    }

    .map-products-page{
        .map-products-table__body{

            .c-table__cell{
                color: white !important;
            }

            a{
                color: white !important;
            }
        }

        .map-products-table__caption{
            background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
        }

        .map-products-table__caption__title{
            background-color: transparent !important; 
            color: white !important;
        }
    }

    .map-listings-page{
        .map-listings-table__caption{
            background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
        }

        .map-listings-table__caption__title{
            background-color: transparent !important; 
            color: white !important;
        }
    }

    .map-inbox-page{
        .map-inbox-table__caption{
            background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
        }

        .map-inbox-table__caption__title{
            background-color: transparent !important; 
            color: white !important;
        }
    }

    .assets-table__body{
        .c-table__cell{
            color: white;
        }
    }

    .leads-table__body{
        .c-table__cell{
            color: white;
        }

        img{
            background-color: white;
            height: auto;
            width: auto;
        }
    }

    .phone-numbers-table__body{
        .c-table__cell{
            color: white;
        }
    }

    .seller-whitelist-table__body{
        .c-table__cell{
            color: white;
        }
    }

    .seller-search-table__body{
        .c-table__cell{
            color: white;
        }
    }




    .map-products-table{
        background: linear-gradient(to top right, #292b2c, #1d1f20) !important;
    }

    .c-navbar{
        background: linear-gradient(to top right, #292b2c, #1d1f20);

        .c-navbar__title, .dropdown-header__title{
            color: white;
        }
    }
    .c-sidebar{
        background: linear-gradient(to top right, #292b2c, #1d1f20);
        //background: linear-gradient(to top right, #0d1f3f, #224a7e);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Add a subtle box shadow for a glassy effect */
        backdrop-filter: blur(10px); /* Apply a blur effect to the background */
        border-bottom: 1px solid #1d1f20 !important;

    }

    .index-page__main-section{
        .rowTitleText{
            span{
                color: white !important;
            }
        }
    }

    .o-page__content{
        .c-dropdown{
            h2{
                color: white !important;
            }
        }
    }
    
    .o-page__content{
        .c-navbar__title{
            h2{
                color: white !important;
            }
        }
    }

  }

  .inverted-table-header-background-text{
    color: #7f8fa4 !important;

  }

  .inverted-text-color{
    color: white !important;
  }

  .inverted-image-background-color{
    background-color: white !important;

  }

  .inverted-no-design {
    background-color: transparent !important; /* or background-color: unset !important; */
  }