.sub-statuses-count {
  .sub-statuses__content {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .sub-statuses__icon-container {
    display: flex;
    width: 56px;

    .sub-statuses__icon {
      width: auto;
      height: auto;
      max-height: 60px;
    }
  }

  .sub-statuses__count {
    display: flex;
    align-items: center;
    transform: translate(-8px, 0);
    color: #000000;
  }

  .sub-statuses__icon--padding {
    padding: 10px;
  }
}
