/* ==========================================================================
   #CLEARFIX UTILITIY
   ========================================================================== */

// sass-lint:disable no-important

/**
 * Clear floated elements by adding `u-clearfix` to the parent element. 
 */

.u-clearfix {
	@include clearfix;
}
