.add-seller-details-modal {
  font-family: "acumin-pro";
  width: 70vw;
  max-height: 657px;
  overflow-y: auto;
  border-radius: 6px;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    padding: 21px 32px 0 32px;
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    height: 35vh;
    margin-top: 25px;
    overflow: auto;

    .col-sm, .col {
      padding-right: 10px;
      padding-left: 10px;
    }

    .form {
      padding: 0 32px 0 32px;

      .c-field__label {
        color: #001D3D;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-field {
        margin-bottom: 15px;

        .c-input {
          font-size: 0.7rem;
          text-align: left;
        }

        .c-btn {
          text-transform: capitalize;
        }

        .dropdown-seller-type {
          width: 9rem
        }
      }
    }
  }

  .modal__actions {
    padding: 0px 32px 21px 32px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .c-btn {
      min-width: 137px;
    }

    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.content-seller-type-bootstrap-feel {
  .dropdown__container {
    width: 8.3rem;

    .dropdown__item {
      padding: 5px 0 5px 0;
      cursor: pointer;
    }
  }
}
