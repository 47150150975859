.violation-detail-modal {
  font-family: "acumin-pro";
  width: 500px;
  max-height: 657px;
  border-radius: 6px;

  .u-color-danger {
    color: #ed1c24!important;
  }

  .modal__header {
    padding: 21px 32px 0 32px;
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .windows-close-btn {
      right: 25px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    max-height: 60vh;
    margin-top: 25px;
    padding: 0 5px 0 5px;

    .col-sm {
      padding-right: 10px;
      padding-left: 10px;
    }

    .form {
      padding: 0 21px 0 21px;

      .c-field__label {
        color: #001D3D;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-field {
        margin-bottom: 10px;

        .violation-detail__info-image {
          position: absolute;
          bottom: 10px;
          right: 5px;
          width: auto;
          max-height: 18px;
        }
      }

      .c-dropdown {
        .c-dropdown__item:hover {
          background-color: $color-map-white;
        }

        .c-btn--outline {
          background: transparent;
      		border-color: #dfe3e9;
      		color: #001D3D;
      		font-weight: 300;
          width: 135.33px;
          text-align: left;

      		&:active,
      		&:focus,
      		&:visited,
      		&:hover {
      			color: #001D3D;
      		}

      		&.has-dropdown {
      			&::after {
      		    margin-left: 0;
      		    padding: 0.5rem 8px 0.5rem 2px;
      		    border-left: 0;
      		    content: "\f0d7";
      			}
      		}
      	}

        .c-dropdown__menu {
          width: 135.33px;
        }
      }
    }

    .table_container {
      max-width: 100%;
      max-height: 16vh;
      overflow-x: auto;
      overflow-y: auto;
      margin-bottom: 5px;

      .c-table {
        .c-table__cell--head {
          padding-top: 14px;
          padding-bottom: 14px;
        }

        .c-table__cell--head,
        .c-table__cell {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }

        .c-table__cell--head {
          color: #001D3D;
        }
      }
    }

    .c-board__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 5px;
      right: 15px;
      left: auto;
    }

    .windows-scroll-btn {
      right: 25px;
    }
  }
}
