h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
strong {
  color: #001D3D;
}

html {
  overflow: hidden;
}

body {
  background-color: #F2F5FA;
}
