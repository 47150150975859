/* ==========================================================================
   #NAVBAR
   ========================================================================== */

/**
 * The site's main page header, used as a wrapper for its sub-components.
 */

.c-navbar {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	min-width: 100%;
	min-height: $navbar-height;
	padding: $navbar-padding;
	border-bottom: $navbar-border-width solid $navbar-border-color;
	//background-color: $navbar-background-color;
  @include respond-to($breakpoint-tablet){
		padding: $spacer-xsmall $spacer-small;
	}
}

.c-navbar_kehe {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/brand_driver_logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 160px 40px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}



.c-navbar_180 {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/logo180logouabide.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 178px 40px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_tlk {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/TLK_Sourcing_Logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 15px;
  background-size: 97px 40px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_crown {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/CrownMerchandise.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 15px;
  background-size: 136px 40px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_vorys {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/vorys.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 15px;
  background-size: 145px 40px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_sunken_stone {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/sunken_stone.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 68px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_amplio {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/amplio.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 174px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_snap {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/snap.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 91px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_palmetto {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/palmetto.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 207px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}



.c-navbar_anderson {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/anderson.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 233px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}


.c-navbar_flywheel {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/flywheel.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 111px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_omniix {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/omniix.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 158px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_hollow {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/hollow.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 104px 50px;
  @include respond-to($breakpoint-tablet){
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_beekeeper {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/beekeeper.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 162px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_quiverr {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/quiverr.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 89px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

//.c-navbar_bluewheelmedia {
//  display: flex;
//  position: relative;
//  flex-wrap: wrap;
//  align-items: center;
//  width: 100%;
//  min-width: 100%;
//  min-height: $navbar-height;
//  padding: $navbar-padding;
//  border-bottom: $navbar-border-width solid $navbar-border-color;
//  //background-color: $navbar-background-color;
//  background-image: url("../img/bluewheelmedia.png");
//  background-repeat: no-repeat;
//  background-attachment: fixed;
//  background-position: center 10px;
//  background-size: 122px 50px;
//  @include respond-to($breakpoint-tablet) {
//    padding: $spacer-xsmall $spacer-small;
//  }
//}

.c-navbar_tesmo {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/tesmo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 163px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}
.c-navbar_treeoflife {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/treeoflife.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 5px;
  background-size: 48px 60px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_i9media {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/i9media.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 7px;
  background-size: 79px 60px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_fortress {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/fortress.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 20px;
  background-size: 140px 20px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}




.c-navbar_splendid {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/splendid.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 78px 40px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_unfi {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/unfi.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 73px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_bluewheelmedia {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/bluewheelmedia.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 15px;
  background-size: 180px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_g2 {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/g2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 15px;
  background-size: 139px 40px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_wingpoint {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/wingpoint.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 22px;
  background-size: 135px 30px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_zuber {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-width: 100%;
    min-height: $navbar-height;
    padding: $navbar-padding;
    border-bottom: $navbar-border-width solid $navbar-border-color;
    //background-color: $navbar-background-color;
    background-image: url("../img/Zuber.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center 22px;
    background-size: 171px 20px;
    @include respond-to($breakpoint-tablet) {
      padding: $spacer-xsmall $spacer-small;
    }
  }


.c-navbar_orcapacific {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/orcapacific.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 22px;
  background-size: 115px 40px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_marketdefense {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/market_defense_bg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 12px;
  background-size: 552px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_kaspien {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/Kaspien.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 12px;
  background-size: 140px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_i2o {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  ////background-color: $navbar-background-color;
  background-image: url("../img/i2oRetailLogo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 84px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}


.c-navbar_commercerev {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/CommerceRev_Secondary_FullColor.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 12px;
  background-size: 133px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_themarsagency {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/TheMarsAgency_RGB_Logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 12px;
  background-size: 307px 40px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_blueoceanbrands {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/blue_ocean_brands.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 10px;
  background-size: 118px 50px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

.c-navbar_photonlegal {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 100%;
  min-height: $navbar-height;
  padding: $navbar-padding;
  border-bottom: $navbar-border-width solid $navbar-border-color;
  //background-color: $navbar-background-color;
  background-image: url("../img/photon_logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 5px;
  background-size: 112px 60px;
  @include respond-to($breakpoint-tablet) {
    padding: $spacer-xsmall $spacer-small;
  }
}

/**
 * Set a fixed width for search input when placed in navbar.
 */

.c-navbar__search {
	width: 270px !important;
}

/**
 * 1. Custom width, height is specififed for proper alignment, adding your own
 *    brand/logo image will require custom styles for proper alignment.
 * 2. On small screens, seperate logo/brand from other navbar components as
 *    navbar links would be collapsed.
 */

.c-navbar__brand {
	display: inline-block;
	width: $navbar-brand-width; /* [1] */
	height: $navbar-brand-height; /* [1] */
	margin: $navbar-brand-margin;

	@include respond-to($breakpoint-tablet){
		margin-right: auto; /* [2] */
	}
}

.c-navbar__title {
	margin: 0;
	padding: 0;
	font-size: $h5-font-size;
}
.c-navbar__title_left_side {
  margin: 0;
  //padding: 0;
  font-size: $h5-font-size;
  float: left;
  padding-top: 5px;
  padding-right: 20px;
}


/* Style variants
   ========================================================================== */

/**
 * Use `c-navbar--inverse` for dark background colors.
 *
 */

.c-navbar--inverse {
	@include linear-gradient(to bottom, $blue-300, $blue-400);

	.c-nav,
	.c-nav__item {
		border-color: $blue-200;
	}

	.c-nav__link {
		color: $white;
	}

	.c-avatar.has-dropdown::after {
		color: $white;
	}

	.c-nav-toggle__icon,
	.c-nav-toggle__icon::before,
	.c-nav-toggle__icon::after {
		background-color: $base-background-color;
		opacity: 0.95;
	}
}
