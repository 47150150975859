.manage-accounts-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 40vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin: 0;
    }
  }

  .modal__body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 60vh;

    .searchBar {
      width: 100%;
      position: relative;
      margin-bottom: 10px;

      .c-field__icon {
        top: 5px !important;
      }
    }

    .user-data {
      width: 100%;
      margin-bottom: 15px;

      .collection {
        height: 80px;
      }
    }

    .collection {
      height: 20vh;
      position: relative;

      div {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
      }
    }

    .c-table {
      display: table;
      .c-table__cell--head {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      .c-table__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .c-table__row-center {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 20px;
        font-size: 0.875rem;
        font-weight: 500;
      }

      .c-table__cell--head,
      .c-table__cell {
        width: 50%;
        &:first-child {
          width: 20%;
          padding-left: 15px;
        }

        &:last-child {
          width: 30%;
        }
      }

      .c-table__cell--head {
        margin-right: 10px;
      }
    }
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    .c-btn {
      min-width: 140px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
