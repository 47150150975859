.manage-account-override-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 50vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;

    .search-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .searchBar {
        width: 81%;
        position: relative;
        margin-bottom: 10px;

        .c-field__icon {
          top: 5px !important;
          right: 10px !important;
        }
      }

      .c-dropdown__menu {
        position: absolute;
        width: 80%;
        top: 100%;
        right: 45px;
        margin: 5px 0 0;
        border: 1px solid #dfe3e9;
        border-radius: 4px;
        background-color: #fff;
        text-align: left;
        z-index: 400;

        .collection {
          height: 20vh;
          position: relative;

          div {
            display: flex;
            justify-content: space-between;
            width: 100% !important;
          }

          li {
            width: 100%;
            text-align: center;
            border-top: 1px solid #F7F7F7;
            padding: 8px 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .collection--empty {
          height: auto;
        }

        &.is-active {
          display: block;
        }
      }
    }

    .account__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 200px;
      overflow-y: auto;
      overflow-y: overlay;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .account__item {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .account__item__index {
          font-size: .8rem;
          position: absolute;
          top: 9px;
          left: 0;
        }

        .account__item__input{
          display: flex;
          position: relative;
          width: 90%;

          .c-close {
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }
    }

    @-moz-document url-prefix() {
      .account__list--windows {
        scrollbar-gutter: stable;
        width: 98%;
        padding-left: 16px;
      }
    }

    .account__list-empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal__body__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 305px;
    margin-top: 25px;

    .c-btn {
      width: 137px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
