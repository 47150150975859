.map-overview-page {
  .map-overview-page__main-section {
    overflow: auto;
    max-height: 90vh;
    padding: 0 52px !important;

    .rowTitle {
      margin-bottom: 10px;
    }

    .rowTitleText {
      height: 21px;
      margin-bottom: 12px;
      overflow: hidden !important;

      .indexTitles {
        font-size: 0.913rem;
      }

      &.rowTitleText-last {
        margin-bottom: 0;
      }
    }

    .c-state-card {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 6px;
      padding: 20px 0 30px 0;
      height: 125px;

      &.c-state-card-left-border {
        border-top-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 6px;
        border-left-width: 3px;
        border-left-color: $color-map-dark-blue-2;
      }

      &.left-border--red {
        border-left-color: #D91111;

        .c-state-card__content {
          .c-state-card__number {
            color: #D91111;
          }
        }
      }

      & > a, div {
        text-align: center;

        &:hover {
          color: #001D3D;
        }

        .c-state-card__number {
          font-size: 1.8rem;
          font-weight: 300;
          color: $color-map-dark-blue-2;
        }

        .c-state-card__number--red {
          color: $color-map-red;
        }

        .c-state-card__meta {
          margin-top: 5px;
          text-transform: capitalize;
          color: $color-map-dark-blue-2;
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .map-overview-page {
    .map-overview-page__main-section {
      padding: 0 26px;
    }
  }
}
