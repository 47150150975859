.map-pricing-history-container{
    //background-color: #EFEFEF;
    width: 750px;
    height: 430px;
    margin: 0 auto;
    border-radius: 10px;
}

.map-pricing-history-title{
    font-size: 25px;
    font-weight: 600;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-pricing-history-orange-underline{
    border: solid 1px #ED6714;
    display: flex;
    justify-content: center;
    width: 240px;
    margin: 0 auto;
}


.map-pricing-history-current-price{
    color: #ED6714;
}
.map-pricing-history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 50px;
}

.map-pricing-history-table th,
.map-pricing-history-table td {
  padding: 8px;
  text-align: center;
}

.map-pricing-history-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.map-pricing-history-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.map-pricing-history-table tbody tr:hover {
  background-color: #ddd;
}

.map-pricing-history-table tbody td {
  //border-bottom: 1px solid #ddd;
}

.map-pricing-history-table tbody td:last-child {
  border-bottom: none;
}

.map-pricing-history-pagination-controls{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
}

.map-pricing-history-pagination-button{
  padding: 0px;
  background-color: transparent;
  border: 0px;
}