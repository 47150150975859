.sub-statuses-cell {
  display: flex;
  justify-content: center;
  .sub-statuses__icon {
    width: auto;
    height: 50px;

    &:not(:last-child) {
      margin-right: 0px;
    }
  }

  .sub-statuses__icon--blank {
    width: 50px;
  }

  .sub-statuses__icon--priority {
    padding: 8.4px;
  }

  .sub-statuses__icon--contact {
    padding: 10px;
  }
}
