.manage-emails-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 50vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 35px;

      .email__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 200px;
        overflow: auto;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        .email__item {
          display: flex;
          justify-content: center;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 15px;
          }

          .email__item__input{
            position: relative;
            width: 95%;

            .c-close {
              right: 0px;
              top: 5px;
              position: absolute;
            }
            .c-deadEmail{
              right: 20px;
              top: 5px;
            }

            .c-hasResponded{
              right: 40px;
              top: 5px;
            }


          }
        }
      }

      .email__list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .email__list__add-email {
        display: flex;
        justify-content: flex-end;
        width: 90%;
      }
    }

  .modal__body__actions {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 305px;
    margin-top: 25px;

    .c-btn {
      width: 137px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &.manage-emails-modal--brand {
    width: 508px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #eff3f6;
    padding: 21px 50px 21px 50px;

    .modal__body {
      font-size: 14px;
      color: #7f8fa4;
    }
  }
}
