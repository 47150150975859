$color-map-red: #D91111;
$color-map-dark-red: #CB002C;

$color-map-light-blue-1: #0AA9FF;
$color-map-light-blue-2: #069CEC;
$color-map-blue: #0076CC;
$color-map-dark-blue-1: #0B1D3D;
$color-map-dark-blue-2: #001D3D;

$color-map-pink: #FE0A5F;
$color-map-dark-pink: #DC04FC;
$color-map-hot-magenta: #FF0ADE;
$color-map-eletric-purple: #B60AFF;

$color-map-yellow: #FEBC0A;
$color-map-dark-yellow: #CB8900;

$color-map-orange: #FE680A;
$color-map-dark-orange: #CB3500;

$color-map-grey: #F5F5F5;
$color-map-dark-grey: #C4C4C4;

$color-map-black: #000000;

$color-map-white: #FFFFFF;
