///* ========================================================================
//   #TOOLTIPS
//   ======================================================================== */


$tooltip-max-height: rem(80px);
$tooltip-padding: rem(10px) rem(15px);
$tooltip-border-radius: $base-border-radius;

$tooltip-background-color: $gunmetal-500;
$tooltip-color: $white;

$tooltip-font-size: $text-font-size;
$tooltip-font-weight: $font-weight-normal;
