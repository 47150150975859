.search-input {
  width: 100%!important;

  .c-field__icon {
    position: absolute;
    top: 14px!important;
    font-size: 1.125rem;
    color: #CED0DA;

    &.is-active {
      color: #1090f1;
    }

    &.c-field__icon--left {
      left: 20px!important;
    }

    &.c-field__icon--right {
      right: 20px!important;
    }
  }

  &.search-input--icon-left {
    .c-input {
      padding-left: 30px;
    }
  }

  &.search-input--icon-right {
    .c-input {
      padding-right: 30px;
    }
  }
}
