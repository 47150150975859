.c-calendar{
    display:inline-block;
    width: auto;
    margin:0;
    padding:0;
    border:1px solid #dfe3e9;
    border-radius:4px;
    background-color:#fff;
    font-size: .7rem;
}
.c-calendar__header{
    display:inline-block;
    font-size:.775rem
}
.c-calendar__nav{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:10px 7.5px;
    border-bottom:1px solid #dfe3e9;
    text-align:center
}
.c-calendar__control{
    color:#bbc5d5
}
.c-calendar__picker{
    padding: 7.5px 10px;
    text-align:center
}
.c-calendar__day{
    color:rgba(53,64,82,.5);
    font-size:.7rem;
    font-weight:400;
    text-transform:uppercase
}
.c-calendar__day abbr{
    border-bottom:0;
    text-decoration:none
}
.c-calendar__date{
    position:relative;
    padding: 0 10px;
    color:#354052;
    cursor:pointer;
    z-index:200
}
.c-calendar__date.is-disabled{
    opacity:.5
}
.c-calendar__date:before{
    display:inline-block;
    position:absolute;
    top:50%;
    right:0;
    left:0;
    width:20px;
    height:20px;
    margin:0 auto;
    padding:0;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    border-radius:100%;
    background-color:#e6eaee;
    text-align:center;
    content:" ";
    opacity:0;
    z-index:-100
}
.c-calendar__date:hover:before{
    opacity:.4
}
.c-calendar__date.is-active{
    color:#fff
}
.c-calendar__date.is-active:before{
    background-color:#1a91eb;
    opacity:1
}
