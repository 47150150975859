.new-seller-details-modal {
  font-family: "acumin-pro";
  width: 70vw;
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 6px;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    padding: 21px 32px 0 32px;
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    max-height: 35vh;
    margin-top: 25px;
    overflow: auto;

    .col-sm, .col {
      padding-right: 10px;
      padding-left: 10px;
    }

    .form {
      padding: 0 32px 0 32px;

      .c-field__label {
        color: #001D3D;
        font-weight: 500;
        font-size: 0.7rem;
      }

      .c-field {
        margin-bottom: 15px;

        .c-input {
          font-size: 0.7rem;
          text-align: left;
        }

        .dropdown-seller-status {
          width: 8.3rem
        }
      }
    }

    .reasons__list {
      max-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #dfe3e9;
      border-radius: 4px;
      padding: 0.59375rem 0.9375rem;

      .reasons__list__item {
        background-color: #FFFFFF;
        padding: 5px;
        font-size: .7rem;

        &:not(:last-child) {
          border-bottom: 1px solid #dfe3e9;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .table-container {
      margin-top: 15px;

      .c-table {
        .c-table__cell--head {
          padding-top: 14px;
          padding-bottom: 14px;
        }

        .c-table__cell--head,
        .c-table__cell {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }

        .c-table__cell--head {
          color: #001D3D;
        }
      }
    }
  }

  .modal__actions {
    padding: 0px 32px 21px 32px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .c-btn {
      min-width: 137px;
    }

    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}



.content-seller-status-bootstrap-feel {
  .dropdown__container {
    width: 7.6rem;

    .dropdown__item {
      padding: 5px 0 5px 0;
      cursor: pointer;
    }
  }
}
