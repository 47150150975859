.sent-notice-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 500px;
  min-height: 229px;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-svg {
      margin-bottom: 13px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 25px;

    .form {
      .c-field__label {
        color: #001D3D;
      }

      .c-field {
        margin-bottom: 15px;
      }

      .c-textarea {
        height: 156px;
      }

      .form__actions {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 265px;

        .c-btn {
          width: 127px;
        }
      }
    }
  }
}
