.update-account-state-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 15px 32px;
  width: 40vw;

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin: 0;
    }
  }

  .modal__body {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;

    .c-field {
      .c-dropdown {
        .c-dropdown__item {
          &:hover {
            background-color: $color-map-white;
          }

          &.active {
            color: #fff;
            text-decoration: none;
            background-color: #007bff;
            box-shadow: none;
          }
        }

        .c-btn--outline {
          background: transparent;
          border-color: #dfe3e9;
          color: #001D3D;
          font-weight: 300;
          text-align: left;

          &:active,
          &:focus,
          &:visited,
          &:hover {
            color: #001D3D;
          }

          &.has-dropdown {
            &::after {
              margin-left: 0;
              padding: 0.5rem 8px 0.5rem 2px;
              border-left: 0;
              content: "\f0d7";
            }
          }
        }

        .c-dropdown__menu {
          width: 100%;
        }
      }
    }
  }

  .modal__actions {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    .c-btn {
      min-width: 140px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
