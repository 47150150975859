.c-btn--secondary {
	color: #001D3D;

	&:hover {
		color: #001D3D;
	}
}

.map-page {
	@mixin base-style {
		font-family: "acumin-pro";
		font-weight: 400;
		color: #fff;
	}

	.c-btn {
		@include base-style;
		background: inherit;
		background-color: #fff;
	}

	.c-btn--secondary {
		background-color: #F5F5F5;
		color: #001D3D;
	}

	.c-btn--info {
		background-color: $color-map-light-blue-1;
		border-color: $color-map-light-blue-1;
	}

	.c-btn--info-gradient{
	border-color: $color-map-light-blue-1;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-light-blue-1),to($color-map-light-blue-1));
		background-color: linear-gradient(to bottom, $color-map-light-blue-1, $color-map-light-blue-1);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-light-blue-1),to($color-map-blue));
			background-color: linear-gradient(to bottom, $color-map-light-blue-1, $color-map-blue);
		}
	}

	.c-btn--primary {
		background-color: $color-map-dark-blue-2;
		border-color: $color-map-dark-blue-2;
	}

	.c-btn--primary-gradient {
		border-color: $color-map-dark-blue-2;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-blue-2),to($color-map-dark-blue-2));
		background-color: linear-gradient(to bottom, $color-map-dark-blue-2, $color-map-dark-blue-2);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-blue-2),to($color-map-black));
			background-color: linear-gradient(to bottom, $color-map-dark-blue-2, $color-map-black);

		}
	}

	.c-btn--secondary {
		background-color: $color-map-grey;
		border-color: $color-map-dark-grey;
	}

	.c-btn--secondary-gradient {
		border-color: $color-map-dark-grey;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-grey),to($color-map-grey));
		background-color: linear-gradient(to bottom, $color-map-grey, $color-map-grey);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-grey),to($color-map-dark-grey));
			background-color: linear-gradient(to bottom, $color-map-grey, $color-map-dark-grey);
		}
	}

	.c-btn--danger {
		background-color: $color-map-pink;
		border-color: $color-map-pink;
	}

	.c-btn--danger-gradient {
		border-color: $color-map-pink;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-pink),to($color-map-pink));
		background-color: linear-gradient(to bottom, $color-map-pink, $color-map-pink);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-pink),to($color-map-dark-red));
			background-color: linear-gradient(to bottom, $color-map-pink, $color-map-dark-red);
		}
	}

	.c-btn--danger-2 {
		background-color: $color-map-hot-magenta;
		border-color: $color-map-hot-magenta;
	}

	.c-btn--danger-2-gradient {
		border-color: $color-map-hot-magenta;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-hot-magenta),to($color-map-hot-magenta));
		background-color: linear-gradient(to bottom, $color-map-hot-magenta, $color-map-hot-magenta);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-hot-magenta),to($color-map-pink));
			background-color: linear-gradient(to bottom, $color-map-hot-magenta, $color-map-pink);
		}
	}

	.c-btn--danger-3 {
		background-color: $color-map-eletric-purple;
		border-color: $color-map-eletric-purple;
	}

	.c-btn--danger-3-gradient {
		border-color: $color-map-eletric-purple;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-eletric-purple),to($color-map-eletric-purple));
		background-color: linear-gradient(to bottom, $color-map-eletric-purple, $color-map-eletric-purple);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-eletric-purple),to($color-map-hot-magenta));
			background-color: linear-gradient(to bottom, $color-map-eletric-purple, $color-map-hot-magenta);
		}
	}

	.c-btn--warning {
		background-color: $color-map-yellow;
		border-color: $color-map-yellow;
	}

	.c-btn--warning-gradient {
		border-color: $color-map-yellow;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-yellow),to($color-map-yellow));
		background-color: linear-gradient(to bottom, $color-map-yellow, $color-map-yellow);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-yellow),to($color-map-dark-yellow));
			background-color: linear-gradient(to bottom, $color-map-yellow, $color-map-dark-yellow);
		}
	}

	.c-btn--orange {
		background-color: $color-map-orange;
		border-color: $color-map-orange;
	}

	.c-btn--orange-gradient {
		border-color: $color-map-orange;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-orange),to($color-map-orange));
		background-color: linear-gradient(to bottom, $color-map-orange, $color-map-orange);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-orange),to($color-map-dark-orange));
			background-color: linear-gradient(to bottom, $color-map-orange, $color-map-dark-orange);
		}
	}

	.c-btn--pink {
		background-color: $color-map-dark-pink;
		border-color: $color-map-dark-pink;
	}

	.c-btn--pink-gradient {
		border-color: $color-map-dark-pink;
		background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-pink),to($color-map-dark-pink));
		background-color: linear-gradient(to bottom, $color-map-dark-pink, $color-map-dark-pink);

		&:hover {
			background: -webkit-gradient(linear,left top,left bottom,from($color-map-dark-pink),to($color-map-pink));
			background-color: linear-gradient(to bottom, $color-map-dark-pink, $color-map-pink);
		}
	}

	.c-btn--outline {
		border-color: #dfe3e9;
		color: #001D3D;
		font-weight: 300;

		&:active,
		&:focus,
		&:visited,
		&:hover {
			color: #001D3D;
		}

		&.has-dropdown {
			&::after {
		    margin-left: 0;
		    padding: 0.5rem 8px 0.5rem 2px;
		    border-left: 0;
		    content: "\f0d7";
			}
		}
	}

	.c-btn--border-bold {
		border-width: 2px;
	}

	// BADGES
	.c-badge {
		padding: 0.5rem .75rem;
		width: 100px;
		font-size: 0.7rem;
		@include base-style;
		text-transform: capitalize;
	}

	.c-badge--info {
		background-color: $color-map-light-blue-1;
	}

	.c-badge--primary {
		background-color: $color-map-dark-blue-2;
	}

	.c-badge--danger {
		background-color: $color-map-pink;
	}

	.c-badge--danger-2 {
		background-color: $color-map-hot-magenta;
	}

	.c-badge--danger-3 {
		background-color: $color-map-eletric-purple;
	}

	.c-badge--warning {
		background-color: $color-map-yellow;
	}

	.c-badge--orange {
		background-color: $color-map-orange;
	}

	.c-badge--pink {
		background-color: $color-map-dark-pink;
	}
}
