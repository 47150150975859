.add-new-user-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 40vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
    }
  }

  .modal__body {
    margin-top: 10px;

    .user-detail {
      padding: 0;
      .row {
        margin: 0;
        .col {
          padding: 0;
        }

        .col-sm {
          padding: 0;

          .c-btn {
            margin-top: 15px;
          }
        }

        .col-sm:not(:last-child) {
          margin-right: 10px
        }
      }

      .user-detail__info-title {
        font-weight: 500;
        font-size: 0.7rem;
      }

      .user-detail__info-input {
        font-size: 0.7rem
      }
    }

    .modal__body__actions {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 15px;

      :last-child {
        margin-left: 10px;
      }
    }
  }
}
