.edit-users-modal {
  font-family: "acumin-pro";
  padding: 21px 32px 21px 32px;
  width: 40vw;

  @media (min-width: 992px) {
    width: 508px;
  }

  .modal__header {
    text-align: center;

    .c-close {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin: 0;
    }
  }

  .modal__body {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-container {
      width: 100%;
      max-height: 40vh;
      overflow-y: auto;
      overflow-x: hidden;

      .c-table {
        .c-table__cell--head {
          padding-top: 14px;
          padding-bottom: 14px;
        }

        .c-table__cell--head,
        .c-table__cell {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }

        .c-table__cell--head {
          color: #001D3D;
        }
      }
    }

    .modal__body__actions {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .c-btn {
        min-width: 140px;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
