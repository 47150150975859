.seller-search-page {
  .seller-search-table {
    display: table !important;
    width: 100%;
    .seller-search-table__caption {
      border: 1px solid #e6eaee;
      border-bottom: 0;

      .seller-search-table__caption__section {
        display: flex;
        width: 100%;
        align-items: center;
        background-color: #fff;
      }

      .c-table__title {
        border: 0;
      }

      .seller-search-table__caption__title {
        margin: 0;
        border: 0;
      }

      .seller-search-table__filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .seller-search-table__filter {
          margin-right: 8px;
          text-transform: capitalize;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .seller-search-table__header {
      top: 86px;
    }

    .table__cell__show-image {
      cursor: pointer;
    }

    tbody {
      width: 100%;

      .c-table__row:not(.c-table__row--empty) {
        cursor: pointer;
      }

      .c-table__cell--empty {
        width: 100%;
      }
    }
  }
}
